import styles from './AdvantagesFeature.module.scss';
import AdvantageCard from './AdvantageCard/AdvantageCard';
import AdvantagesSlider from './AdvantagesSlider/AdvantagesSlider';
import { FeaturesSectionType } from '@app/models/sections/features';

export default function AdvantagesFeature({ section }: FeaturesSectionType) {
    return (
        <div className={styles['feature']}>
            <h2 className={styles['feature-title']}>{section.title}</h2>
            <div className={styles['cards']}>
                {section.blocks.map((block, index) => (
                    <AdvantageCard
                        key={block.id}
                        text={block.title}
                        imageSrc={block.image}
                        backgroundFilter={index !== 0}
                        wide={index === 0}
                    />
                ))}
            </div>
            <div className={styles['slider-wrapper']}>
                <AdvantagesSlider showNextSlide>
                    {section.blocks.map((block, index) => (
                        <AdvantageCard
                            key={block.id}
                            text={block.title}
                            imageSrc={block.image}
                            backgroundFilter={index !== 0}
                            wide={index === 0}
                        />
                    ))}
                </AdvantagesSlider>
            </div>
        </div>
    );
}
