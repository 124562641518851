'use client';

import React, { useCallback } from 'react';
import Button, { ButtonSize } from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { startRegistration } from '@app/ducks/common/registration';
import { ClientType } from '@app/models/common/common';
import { sendEvent } from '@app/ducks/common/analytics';

import styles from './BecomePartnerForm.module.scss';

interface IProps {
    block?: boolean;
    size?: ButtonSize;
    type?: 'short' | 'long';
    className?: string;

    /**
     * Текст на кнопке
     */
    buttonText?: string;
}

type Props = IProps & {
    startRegistration: typeof startRegistration;
    sendEvent: typeof sendEvent;
};

const BecomePartnerForm = (props: Props) => {
    const {
        block = false,
        size = 'big',
        type = 'short',
        startRegistration,
        sendEvent,
        className,
        buttonText,
    } = props;

    const text = buttonText || 'Стать партнером';

    const didButtonClicked = useCallback(() => {
        sendEvent('partner-button');
        startRegistration(ClientType.PARTNER);
    }, []);

    return (
        <Button
            text={text}
            size={size}
            noSpacing
            block={block}
            onClick={didButtonClicked}
            className={mergeClassNames([className, buildClassNames(styles, ['button', type])])}
        />
    );
};

export default connectFunctionalComponent(BecomePartnerForm, {
    dispatch: {
        startRegistration,
        sendEvent,
    },
}) as React.FC<IProps>;
