import { HistoryFeatureType } from '@app/models/common/common';
import {
    InvestmentsBannersFeatureType,
    InvestmentsSharesStepsFeatureType,
} from '@app/models/features/investments';
import { IFeatureBlockApiModel } from '@app/models/sections/features';

export type FeatureSectionType = 'history' | 'banners' | 'shares' |'null'

export const getBlockType = (block: IFeatureBlockApiModel<HistoryFeatureType | InvestmentsBannersFeatureType | InvestmentsSharesStepsFeatureType>): FeatureSectionType => {
    switch (block.id) {
    case HistoryFeatureType.YEAR_2019:
    case HistoryFeatureType.YEAR_2020:
    case HistoryFeatureType.YEAR_2021:
    case HistoryFeatureType.YEAR_2022:
    case HistoryFeatureType.YEAR_2023:
    case HistoryFeatureType.YEAR_2024:
        return 'history';
    case InvestmentsBannersFeatureType.BANNERS_DYNAMIC:
    case InvestmentsBannersFeatureType.BANNERS_ADVANTAGES:
    case InvestmentsBannersFeatureType.BANNERS_PRIVILEGE:
        return 'banners';
    case InvestmentsSharesStepsFeatureType.SHARES_STEP_1:
    case InvestmentsSharesStepsFeatureType.SHARES_STEP_2_1:
    case InvestmentsSharesStepsFeatureType.SHARES_STEP_2_2:
    case InvestmentsSharesStepsFeatureType.SHARES_STEP_3:
        return 'shares';
    default:
        return 'null';
    }
};
