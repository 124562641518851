import CompaniesSlider from './CompaniesSlider/CompaniesSlider';
import CompanyCard from './CompanyCard/CompanyCard';
import { IInvestedCompany } from '@app/models/sections/companies';

export interface IProps {
    companies: IInvestedCompany[];
}

/**
 * Компонент с слайдером списка компаний получателей займов
 */
export default function CompaniesSection({ companies }: IProps) {
    return (
        <CompaniesSlider>
            {companies?.map(company => (
                <CompanyCard
                    key={company.id}
                    company={company}
                />
            ))}
        </CompaniesSlider>
    );
}