import React from 'react';
import { IClassName } from '@frontend/jetlend-core/src/models/common';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { IMarkupBlockApiModel } from '@app/models/sections/markup_blocks';
import HtmlMarkup from '../../../components/HtmlMarkup/HtmlMarkup';
import Image from '@app/components/Image/Image';
import styles from './MarkupBlock.module.scss';

export interface IProps extends IMarkupBlockApiModel, IClassName {}

export default function MarkupBlock({
    title,
    content,
    image,
    className: externalClassName,
}: IProps) {
    return (
        <div className={mergeClassNames([ styles['container'], externalClassName ])}>
            <div className={styles['header']}>
                {image && (
                    <div className={styles['image']}>
                        <Image src={image} width={105} height={105} alt="" />
                    </div>
                )}
                <h3 className={styles['title']}>
                    {title}
                </h3>
            </div>
            <HtmlMarkup tag="div" className={styles['content']} markup={content} />
        </div>
    );
}
