
export enum InvestmentsBannersFeatureType {
    BANNERS_DYNAMIC = 'banners-dynamic',
    BANNERS_ADVANTAGES = 'banners-advantages',
    BANNERS_PRIVILEGE = 'banners-privilege',
}

export enum InvestmentsSharesStepsFeatureType {
    SHARES_STEP_1 = 'shares-step-1',
    SHARES_STEP_2_1 = 'shares-step-2.1',
    SHARES_STEP_2_2 = 'shares-step-2.2',
    SHARES_STEP_3 = 'shares-step-3',
}

export enum InvestmentsPresentationFeatureType {
    PRESENTATION_CAGR = 'presentation_cagr',
}

interface ICreditPortfolioColumn {
    /**
     * Дата начала квартала
     */
    start_date: string;
    /**
     * Значение по Y в млрд(0.5млрд, 1млрд, 1.5млрд и т.д.)
     */
    amount: number;
}

export type CreditPortfolioApiModel = ICreditPortfolioColumn[];

export enum InvestmentsChartType {
    /**
     * График "Наша доля на рынке за 12 месяцев"
     */
    MARKET_SHARE = 'market-share',
    /**
     * График "Сферы бизнеса и отрасли экономики, которым мы помогаем развиваться"
     */
    BUSINESS_AREAS = 'business-areas',
    /**
     * График "Рост кредитного портфеля платформы до 10 млрд. руб"
     */
    CREDIT_PORTFOLIO = 'credit-portfolio',
    /**
     * График "Динамика выручки"
     */
    COMPANY_REVENUE = 'company-revenue',
}


export enum IChartDataType {
    /**
     * Значение в валюте
     */
    AMOUNT = 'amount',
    /**
     * Абсолютное значение
     */
    COUNT = 'count',
}

export interface IChartDataApiModel {
    /**
     * Общее количество
     */
    total: number;
    /**
     * Тип основного значения на графике
     */
    type: IChartDataType;
    /**
     * Список слайсов
     */
    values: IChartValueApiModel[];
}

export interface IChartValueApiModel {
    /**
     * Название опции
     */
    title: string;
    /**
     * Процентное значение
     */
    part: number;
}

/**
 * Данные для графика "Рост кредитного портфеля"
 */
export interface ICreditPortfolioChartValues {
    /**
     * Значение по X
     */
    x: number;
    /**
     * Значение по Y
     */
    y: number;
    /**
     * Название
     */
    title: string;
    /**
     * Цвет колонки
     */
    color: string;
}

export const QUARTERS_MAP = {
    1: 'янв. — март',
    2: 'апр. — июн.',
    3: 'июл. — сен.',
    4: 'окт. — дек.',
};

export interface ICompanyYearRevenueApiModel {
    /**
     * Год
     */
    year: number;
    /**
     * Сумма выручки в рублях
     */
    amount: number;
    /**
     * Является ли точка прогнозной
     */
    forecast: boolean;
}

export interface IMarketYearVolumeForecastApiModel {
    /**
     * Год
     */
    year: number;
    /**
     * Сумма выручки в рублях
     */
    billions_amount: number;
}

export interface IInvestmentsPresentationStateModel {
    /**
     * Ссылка на презентацию
     */
    href?: string;
}
