import { FC } from 'react';
import styles from './ProductCard.module.scss';
import SectionPart from '@app/ui/SectionPart/SectionPart';
import Image from '@app/components/Image/Image';

interface IProps {
    className?: string;
    titleText: string;
    descriptionText: string;
}

const ProductCard: FC<IProps> = props => {
    const {
        className,
        titleText,
        descriptionText,
    } = props;
    return (
        <SectionPart className={className}>
            <div className={styles['content']}>
                <div className={styles['title-wrapper']}>
                    <h3 className={styles['title']}>{titleText}</h3>
                </div>
                <div className={styles['description-wrapper']}>
                    <p className={styles['description']}>{descriptionText}</p>
                </div>
                <div className={styles['image-wrapper']}>
                    <div className={styles['image']}>
                        <Image src="/next/assets/partners-phone.png" alt="iphone-app" priority fill />
                    </div>
                </div>
            </div>
        </SectionPart>
    );
};

export default ProductCard;
