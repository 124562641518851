'use client';

import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    isMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { Suspense } from 'react';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import dynamic from 'next/dynamic';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import { numWordForm } from '@frontend/jetlend-core/src/utils/langUtils';
import SectionPart from '@app/ui/SectionPart/SectionPart';
import InvestorsLiquidityFeature from '@app/features/investors/InvestorsLiquidityFeature/InvestorsLiquidityFeature';
import SectionExpandedArea from '@app/ui/SectionExpandedArea/SectionExpandedArea';
import OffscreenLoader from '@app/ui/OffscreenLoader/OffscreenLoader';
import InvestorsLiquidityDomTable from './InvestorsLiquidityDomTable/InvestorsLiquidityDomTable';
import styles from './InvestorsFeaturesSection.module.scss';
import { IInvestorsFeatureType } from '@app/models/features/investors';
import { investorsJetLendStatisticsHandler } from '@app/ducks/investors/investors';
import { FeaturesSectionType } from '@app/models/sections/features';

const LazyInvestorsYieldChart = dynamic(() => import('@app/features/investors/InvestorsYieldChart/InvestorsYieldChart'), { ssr: false });
const LazyReliabilityChart = dynamic(() => import('@app/features/investors/InvestorsReliabilityChart/InvestorsReliabilityChart'), { ssr: false });

export default function InvestorsFeaturesSection({
    section,
}: FeaturesSectionType) {
    const [ stats ] = useSimpleApiHandler(investorsJetLendStatisticsHandler);

    const profitability = section.blocks.find(block => block.id === IInvestorsFeatureType.PROFITABILITY);
    const reliability = section.blocks.find(block => block.id === IInvestorsFeatureType.RELIABILITY);
    const liquidity = section.blocks.find(block => block.id === IInvestorsFeatureType.LIQUIDITY);

    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);
    const isTabletScreen = isTablet(screenWidth);

    const yieldContainerStyles = mergeClassNames([
        styles['profitability__chart-container'],
    ]);

    const profitabilityChartClassName = buildClassNames(styles, [
        'chart',
        'profitability__chart',
    ]);

    const reliabilityChartClassName = buildClassNames(styles, [
        'chart',
        'reliability__chart',
    ]);

    // TODO Order blocks based on API response

    return (
        <div>
            {profitability && (
                <SectionPart
                    className={styles['profitability']}
                // animation="slide-up"
                // animationThreshold={0}
                >
                    <div className={yieldContainerStyles}>
                        <h3 className={buildClassNames(styles, ['heading', 'profitability__heading'])}>{profitability.title}</h3>
                        <OffscreenLoader>
                            {({
                                inView,
                                ref,
                            }) => (
                                <SectionExpandedArea ref={ref} className={profitabilityChartClassName}>
                                    <Suspense fallback={<LoaderBlock />}>
                                        {inView && (
                                            <LazyInvestorsYieldChart
                                                height={isMobileScreen || isTabletScreen ? 250 : 330}
                                                hideSizeSwitcher
                                                noBorder
                                            />
                                        )}
                                    </Suspense>
                                </SectionExpandedArea>
                            )}
                        </OffscreenLoader>

                        <div className={styles['profitability__description-container']}>
                            <p className={styles['info-value']}>
                                {profitability.value}
                            </p>
                            <p className={styles['profitability__info-description']}>
                                {profitability.description}
                            </p>
                        </div>
                    </div>
                </SectionPart>
            )}
            <div className={`grid-row ${styles['grid-container']}`}>
                {reliability && (
                    <SectionPart
                        className={`grid-column-6 ${styles['reliability']}`}
                        // animation="slide-right"
                    >
                        <h3 className={styles['heading']}>{reliability.title}</h3>
                        <OffscreenLoader>
                            {({
                                inView,
                                ref,
                            }) => (
                                <SectionExpandedArea ref={ref} className={reliabilityChartClassName}>
                                    <Suspense fallback={<LoaderBlock />}>
                                        {inView && (
                                            <>
                                                <LazyReliabilityChart
                                                    // eslint-disable-next-line no-nested-ternary
                                                    height={isMobileScreen ? 250 : isTabletScreen ? 310 : 350}
                                                />
                                                <div className={styles['reliability__chart-info']}>
                                                    Данные за последние {numWordForm(stats?.jl_on_strategy_term, 'day')} по стратегиям Автоинвестирования
                                                </div>
                                            </>
                                        )}
                                    </Suspense>
                                </SectionExpandedArea>
                            )}
                        </OffscreenLoader>
                        <div className={styles['reliability__info']}>
                            <p className={styles['info-value']}>
                                {reliability.value}
                            </p>
                            <p className={styles['reliability__info-description']}>
                                {reliability.description}
                            </p>
                        </div>
                    </SectionPart>
                )}
                {liquidity && (
                    <SectionPart
                        className="grid-column-6"
                    // animation="slide-left"
                    >
                        <h3 className={styles['heading']}>{liquidity.title}</h3>
                        <p className={styles['liquidity__info-description']}>
                            {liquidity.description}
                        </p>
                        <InvestorsLiquidityFeature
                            domTable={<InvestorsLiquidityDomTable />}
                        />
                    </SectionPart>
                )}
            </div>
        </div>
    );
};