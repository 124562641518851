import React, { memo } from 'react';
import LayoutFooterSectionPortal from '@app/components/LayoutFooter/LayoutFooterSectionPortal';
import InvestmentCalculatorNotes from '@app/features/investors/InvestmentCalculator/InvestmentCalculatorNotes';

const InvestmentCalculatorFooter = memo(() => (
    <LayoutFooterSectionPortal sectionType="notes">
        <InvestmentCalculatorNotes />
    </LayoutFooterSectionPortal>
));

export default InvestmentCalculatorFooter;
