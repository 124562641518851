import React, { PropsWithChildren } from 'react';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './CommonCard.module.scss';

export interface IProps extends PropsWithChildren {
    /**
     * Не использовать рамки
     */
    noBorder?: boolean;
    /**
     * Класс
     */
    className?: string;
}

/**
 * Базовый компонент карточки слайдера
 */
export default function CommonCard({
    noBorder,
    className,
    children,
}: IProps) {
    const classes = mergeClassNames([
        styles['card'],
        noBorder && ['card--no-border'],
        className,
    ]);

    return (
        <div className={classes}>
            {children}
        </div>
    );
};
