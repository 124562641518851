'use client';

import React, {
    useMemo,
    useState,
    Suspense,
} from 'react';
import commonChartStyles from '@frontend/jetlend-web-ui/src/controls/charts/styles/common.module.scss';
import dynamic from 'next/dynamic';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import { cnx } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import useObjectHandler from '@frontend/jetlend-core/src/hooks/useObjectHandler';
import {
    useWindowSize,
    isMobile,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import OffscreenLoader from '@app/ui/OffscreenLoader/OffscreenLoader';
import InvestmentCalculatorNotes from '../InvestmentCalculatorNotes';
import { IInvestmentChartSeries } from '../../InvestmentChart/InvestmentChart';
import ChartTabItemButton from './ChartTabItemButton';
import ConnectedStrategyExpectedYieldChart from '../StrategyExpectedYieldChart/ConnectedStrategyExpectedYieldChart';
import { investmentCalculatorHandler } from '@app/ducks/investors/investors';

import styles from './chartSection.module.scss';

const LazyInvestmentChart = dynamic(() => import('../../InvestmentChart/InvestmentChart'), { ssr: false });

export enum ChartItem {
    INTERVAL = 'interval',
    YIELD = 'yield',
}

const TABS = [
    {
        id: ChartItem.YIELD,
        title: 'Прогноз нормального распределения',
        title_mobile: 'Нормальное распределение',
    },
    {
        id: ChartItem.INTERVAL,
        title: 'График ожидаемой доходности',
        title_mobile: 'Ожидаемая доходность',
    },
];

/**
 * Компонент для отрисовки графиков (Доверительный интервал и Ожидаемая доходность) калькулятора.
 */
const ChartSection = () => {
    const [{ result }] = useObjectHandler(investmentCalculatorHandler);
    const [tabId, setTabId] = useState(ChartItem.INTERVAL);
    const { width } = useWindowSize();
    const isMobileScreen = isMobile(width);

    const investmentChartSeries: IInvestmentChartSeries[] = useMemo<IInvestmentChartSeries[]>(() => ([
        {
            name: 'JetLend',
            color: '#20836D',
            volatile: true,
            data: result?.jetlendChart,
        },
        {
            name: 'Акции',
            color: '#EB0038',
            volatile: true,
            data: result?.stocksChart,
        },
        {
            name: 'Облигации',
            color: '#F2C94C',
            volatile: true,
            data: result?.bondsChart,
        },
        {
            name: 'Депозит',
            color: '#4147D1',
            data: result?.depositsChart,
        },
        {
            name: 'Жилая недвижимость',
            color: '#00C2ED',
            data: result?.realEstatesChart,
        },
    ]), [result]);

    return (
        <>
            <div className={styles['tabs']}>
                {TABS.map(item => (
                    <ChartTabItemButton
                        key={item.id}
                        tabId={item.id}
                        title={isMobileScreen ? item.title_mobile : item.title}
                        active={item.id === tabId}
                        onChange={setTabId}
                    />
                ))}
            </div>
            <OffscreenLoader>
                {({
                    inView,
                    ref,
                }) => (
                    <div ref={ref} className={cnx([ styles['chart'], commonChartStyles['chart'] ])}>
                        <Suspense fallback={<LoaderBlock />}>
                            {inView && (
                                <>
                                    {tabId === ChartItem.YIELD && <ConnectedStrategyExpectedYieldChart /> }
                                    {tabId === ChartItem.INTERVAL && (
                                        <>
                                            <LazyInvestmentChart series={investmentChartSeries} />
                                            <div className="r-not-mobile h-mt-30">
                                                <InvestmentCalculatorNotes />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </Suspense>
                    </div>
                )}
            </OffscreenLoader>
        </>
    );
};

export default ChartSection;
