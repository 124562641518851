'use client';

import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { useRef } from 'react';
import HtmlMarkup from '@app/components/HtmlMarkup/HtmlMarkup';
import SectionPart from '@app/ui/SectionPart/SectionPart';
import styles from './RollupSection.module.scss';
import ExpandControl from './ExpandControl/ExpandControl';
import { RollupSectionType } from '@app/models/sections/rollup';

/**
 * Компонент для отображения длинного текста свернутым, с возможностью развернуть его
 */
export default function RollupSection({ section }: RollupSectionType) {
    const wrapperRef = useRef(null);

    return (
        <SectionPart className={styles['container']}>
            <HtmlMarkup tag="h2" markup={section.title} className={styles['title']} />
            <div className={styles['content']}>
                <div className={styles['info']}>
                    <ExpandControl
                        labelClassName={styles['label']}
                        checkboxClassName={styles['checkbox']}
                        wrapperRef={wrapperRef}
                    />
                    {section.content && (
                        <HtmlMarkup
                            ref={wrapperRef}
                            className={buildClassNames(styles, ['main-text', 'shadow'])}
                            tag="div"
                            markup={section.content}
                        />
                    )}
                </div>
            </div>
        </SectionPart>
    );
}
