'use client';

import HistoryFeature from '@app/components/HistoryFeature/HistoryFeature';
import { getBlockType } from './InvestmentsFeaturesSection.logic';
import InvestmentsBannersSection from '@app/features/investments/InvestmentsBannersSection/InvestmentsBannersSection';
import InvestmentsSharesStepsSection from '@app/features/investments/InvestmentsSharesStepsSection/InvestmentsSharesStepsSection';
import { FeaturesSectionType } from '@app/models/sections/features';

export default function InvestmentsFeaturesSection({ section }: FeaturesSectionType) {
    if (!section?.blocks || !section?.blocks?.length) {
        return null;
    }

    const sectionType = getBlockType(section.blocks[0]);

    const config = {
        'history': <HistoryFeature section={section} />,
        'banners': <InvestmentsBannersSection section={section} />,
        'shares': <InvestmentsSharesStepsSection section={section} />,
        'null': null,
    };

    return config[sectionType];
};
