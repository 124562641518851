'use client';

import React from 'react';
import {
    isTabletPortrait,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import InvestorsCommonSlideSection from '@app/features/investors/InvestorsCommonSlideSection/InvestorsCommonSlideSection';
import PartnersItem from '@app/sections/PartnersSection/PartnersItem';
import SlideLink from '@app/features/investors/InvestorsCommonSlideSection/SlideLink/SlideLink';
import { PartnersSectionType } from '@app/models/sections/partners';

export default function PartnersSection({ section }: PartnersSectionType) {
    const { width } = useWindowSize();
    const isTablet = isTabletPortrait(width);

    return (
        <InvestorsCommonSlideSection
            card={false}
            amount={isTablet ? 14 : 7}
        >
            {section.items?.map((item, index) => (
                <SlideLink key={item.url ?? `__${index}`} url={item.url}>
                    <PartnersItem {...item} />
                </SlideLink>
            ))}
        </InvestorsCommonSlideSection>
    );
}
