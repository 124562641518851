'use client';

import styles from './DocumentsSection.module.scss';
import { DocumentsSectionType } from '@app/models/sections/documents';
import DocumentsList from './DocumentsList/DocumentsList';

/**
 * Компонент секции для отображения простого списка документов на странице.
 * Без фильтрации и поиска.
 */
export default function DocumentsSection({ section }: DocumentsSectionType) {
    return (
        <div className={styles['block-body']}>
            <DocumentsList documents={section?.documents} allowFilter={section?.allowFilter} />
        </div>
    );
}
