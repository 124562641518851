import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { IDepartmentConfig } from './departments';
import styles from './EmployeeDepartmentBlock.module.scss';
import { Department } from '@app/models/features/aboutPlatform';

interface IFilterButtonsBlockProps {
    departments: Record<Department, IDepartmentConfig>;
    filter: string;
    onDepartmentSelect: (department: Department) => () => void;
}

const FilterButtonsBlock = ({
    departments,
    onDepartmentSelect,
    filter,
}: IFilterButtonsBlockProps) => {

    const isActive = (filterName: Department) => filterName === filter;

    return (
        <>
            {Object.entries(departments).map(([ department, config ]: [ Department, IDepartmentConfig ]) => (
                <Button
                    className={buildClassNames(styles, [
                        'filter__button',
                        `filter__button--department-${department}`,
                    ])}
                    containerClassName={styles['filter__button_container']}
                    key={department}
                    text={config.text}
                    icon={isActive(department) ? config.iconActive || '' : config.icon || ''}
                    outline={!isActive(department)}
                    onClick={onDepartmentSelect(department)}
                />
            ))}
        </>
    );
};

export default FilterButtonsBlock;
