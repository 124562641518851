'use client';

import React, { useRef } from 'react';
import { SlideProps } from 'react-slideshow-image/dist/types';
import dynamic from 'next/dynamic';
import CommonCard from './CommonCard/CommonCard';
import styles from './PartnerWorkflowSlider.module.scss';
import './slider.scss';
import OffscreenLoader from '@app/ui/OffscreenLoader/OffscreenLoader';

const LazySlide = dynamic(() => import('react-slideshow-image').then(module => module.Slide), { ssr: false });

export interface IProps {
    children: React.ReactNode[];
    card?: boolean;
    noBorder?: boolean;
    showNextSlide?: boolean;
}

const RESPONSIVE_SETTINGS = [
    {
        breakpoint: 1600,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 0.5,
        },
    },
    {
        breakpoint: 569,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 0,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
];

const PartnerWorkflowSlider: React.FC<IProps> = props => {
    const {
        card = true,
        showNextSlide,
    } = props;

    const sliderRef = useRef<SlideProps | null>(null);

    const SLIDER_SETTINGS: SlideProps = {
        children: [],
        arrows: false,
        indicators: false,
        autoplay: false,
        infinite: true,
        cssClass: styles['slider'],
        duration: 1200,
        transitionDuration: 400,
        canSwipe: true,
        ref: sliderRef,
        responsive: RESPONSIVE_SETTINGS,
        slidesToScroll: 0,
    };

    return (
        <OffscreenLoader>
            {({
                ref,
                inView,
            }) => (
                <div ref={ref} className={styles['container']}>
                    <div className={showNextSlide && styles['slim']}>
                        {inView && (
                            <LazySlide {...SLIDER_SETTINGS}>
                                {props.children.map((item, i) => (
                                    <React.Fragment key={i}>
                                        {card
                                            ? (
                                                <CommonCard noBorder={props.noBorder}>{item}</CommonCard>
                                            )
                                            : (
                                                <div className={styles['item-wrapper']}>{item}</div>
                                            )}
                                    </React.Fragment>
                                ))}
                            </LazySlide>
                        )}
                    </div>
                </div>
            )}
        </OffscreenLoader>
    );
};

export default PartnerWorkflowSlider;
