'use client';

import React, {
    useMemo,
    useState,
} from 'react';
import Expand from 'react-expand-animated';
import IconButton from '@frontend/jetlend-web-ui/src/ui/inputs/IconButton/IconButton';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import useEventCallback from '@frontend/jetlend-core/src/hooks/useEventCallback';
import styles from './FaqItem.module.scss';
import plusIcon from '@app/icons/plus-icon.svg';
import { IFaqItemApiModel } from '@app/models/sections/faq';
import HtmlMarkup from '@app/components/HtmlMarkup/HtmlMarkup';

export interface IProps {
    /**
     * Элемент FAQ.
     */
    item: IFaqItemApiModel;
}

function isHtmlContentWrapped(content: string): boolean {
    if (!content) {
        return false;
    }

    return content.startsWith('<') && content.endsWith('>') ||
        content.includes('<p') ||
        content.includes('<br');
}

function renderMultilineContent(content: string): JSX.Element | React.ReactNode {
    if (!content) {
        return null;
    }

    const lines = content.split(/[\r\n]+/gm);

    return (
        <>
            {lines.map((line, index) =>
                <p key={index}>{line}</p>
            )}
        </>
    );
}

/**
 * Компонент для отображения одного элемента FAQ с возможностью раскрытия/сворачивания.
 */
export default function FaqItem({ item }: IProps): JSX.Element {
    const [expanded, setExpanded] = useState(false);

    const didToggleClicked = useEventCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        setExpanded(expand => !expand);
    });

    const isHtml = useMemo(() => isHtmlContentWrapped(item?.description), [item]);

    return (
        <div className={styles['block']} onClick={didToggleClicked}>
            <div className={styles['header']}>
                <div className={styles['title']}>
                    {item.title}
                </div>
                <IconButton
                    size="normal"
                    icon={plusIcon}
                    containerClassName={buildClassNames(styles, ['icon-container', expanded && 'icon-container--rotated'])}
                />
            </div>
            <Expand duration={400} open={expanded}>
                <div className={styles['body']}>
                    {isHtml
                        ? (
                            <HtmlMarkup tag="div" className={styles['description']} markup={item.description} />
                        )
                        : (
                            <div className={styles['description']}>
                                {renderMultilineContent(item.description)}
                            </div>
                        )}
                </div>
            </Expand>
        </div>
    );
};