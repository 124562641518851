import React from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import PieChartBlock from '@app/components/PieChartBlock/PieChartBlock';
import { marketShareChartHandler } from '@app/ducks/investments';
import { InvestmentsChartType } from '@app/models/features/investments';
import { ChartSectionType } from '@app/models/sections/chart';

/**
 * Круговой график "Доля на рынке"
 */
export default function InvestmentsMarketShareChart({ section }: ChartSectionType<InvestmentsChartType>) {
    const [chartData] = useSimpleApiHandler(marketShareChartHandler);

    return <PieChartBlock section={section} chartData={chartData} />;
}