'use client';

import { useMemo } from 'react';
import { IPartnersFeatureType } from '@app/models/features/partners';
import RevenueFeature from './RevenueFeature/RevenueFeature';
import OnlineFeature from './OnlineFeature/OnlineFeature';
import ServiceFeature from './ServiceFeature/ServiceFeature';
import {
    FeaturesSectionType,
    IFeatureBlockApiModel,
} from '@app/models/sections/features';

export default function PartnersFeaturesSection({
    section: {
        blocks,
        title,
        description,
    },
}: FeaturesSectionType) {
    const sectionBlocks = useMemo(
        () =>
            blocks.reduce((acc, block) => {
                switch (block.id) {
                case IPartnersFeatureType.REVENUE:
                    acc.revenue = block;
                    break;
                case IPartnersFeatureType.AUTOWITHDRAW:
                    acc.autowithdraw = block;
                    break;
                case IPartnersFeatureType.BEST_PRODUCT:
                    acc.bestProduct = block;
                    break;
                case IPartnersFeatureType.ONLINE_DOCUMENTS:
                    acc.onlineDocuments = block;
                    break;
                case IPartnersFeatureType.ONE_DAY_REGISTRATION:
                    acc.oneDayRegistration = block;
                    break;
                case IPartnersFeatureType.PERSONAL_MANAGER:
                    acc.personalManager = block;
                    break;
                case IPartnersFeatureType.ALL_IN_ONE_CABINET:
                    acc.allInOneCabinet = block;
                    break;
                case IPartnersFeatureType.REFERRAL_LINKS:
                    acc.referralLinks = block;
                    break;
                case IPartnersFeatureType.API_INTEGRATIONS:
                    acc.apiIntegrations = block;
                    break;
                default:
                    break;
                }
                return acc;
            }, {} as Record<string, IFeatureBlockApiModel<IPartnersFeatureType>>),
        [blocks]
    );

    const {
        revenue,
        autowithdraw,
        bestProduct,
        onlineDocuments,
        oneDayRegistration,
        personalManager,
        allInOneCabinet,
        referralLinks,
        apiIntegrations,
    } = sectionBlocks;

    const revenueBlocks = {
        revenue,
        autowithdraw,
        bestProduct,
    };
    const onlineBlocks = {
        onlineDocuments,
        oneDayRegistration,
        personalManager,
    };
    const serviceBlocks = {
        allInOneCabinet,
        referralLinks,
        apiIntegrations,
    };

    if (revenue && autowithdraw && bestProduct) {
        return <RevenueFeature sectionBlocks={revenueBlocks} title={title} />;
    }

    if (onlineDocuments && oneDayRegistration && personalManager) {
        return <OnlineFeature sectionBlocks={onlineBlocks} title={title} description={description} />;
    }

    if (allInOneCabinet && referralLinks && apiIntegrations) {
        return <ServiceFeature sectionBlocks={serviceBlocks} title={title} />;
    }

    return null;
}
