'use client';

import React, { useCallback } from 'react';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import SectionPart from '@app/ui/SectionPart/SectionPart';
import styles from './PlatformFeature.module.scss';
import { startRegistration as startRegistrationAction } from '@app/ducks/common/registration';
import { sendEventHandler } from '@app/ducks/common/analytics';
import { ClientType } from '@app/models/common/common';
import { IFeatureBlockApiModel } from '@app/models/sections/features';

export default function PlatformFeature({
    title,
    description,
    value,
    id,
}: IFeatureBlockApiModel) {
    const startRegistration = useActions(startRegistrationAction);
    const sendEvent = useActions(sendEventHandler.action);

    const didBecomeInvestorClicked = useCallback(() => {
        startRegistration(ClientType.INVESTOR);
        sendEvent('investor-button');
    }, [sendEvent, startRegistration]);

    const didBecomeBorrowerClicked = useCallback(() => {
        startRegistration(ClientType.BORROWER);
        sendEvent('borrower-button');
    }, [sendEvent, startRegistration]);

    const didButtonClicked = useCallback(() => {
        if (id === 'investors') {
            didBecomeInvestorClicked();
            return;
        }
        if (id === 'borrowers') {
            didBecomeBorrowerClicked();
        }
    }, [id, didBecomeBorrowerClicked, didBecomeInvestorClicked]);

    return (
        <SectionPart className={styles['container']}>
            <div className={styles['content']}>
                <div className={styles['title']}>{title}</div>
                <div className={styles['description']}>{description}</div>
                <button
                    type="button"
                    className={mergeClassNames([styles['value'], 'link'])}
                    onClick={didButtonClicked}
                >
                    {value}
                </button>
            </div>
        </SectionPart>
    );
}
