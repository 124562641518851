import ShowMore from '@app/ui/ShowMore/ShowMore';
import styles from './LinksFeature.module.scss';
import { FeaturesSectionType } from '@app/models/sections/features';

export default function LinksFeature({ section }: FeaturesSectionType) {
    return (
        <div className={styles['links-feature']}>
            <span className={styles['feature-description']}>
                {section.description}
            </span>
            <h2 className={styles['feature-title']}>{section.title}</h2>
            <div className={styles['links']}>
                <ShowMore className={styles['link']} linkText="Инвесторам" linkHref="/investor" />
                <ShowMore className={styles['link']} linkText="Предпринимателям" linkHref="/borrower/kreditovanie-biznesa/" />
                <ShowMore className={styles['link']} linkText="Партнерам" linkHref="/partneram" />
            </div>
        </div>
    );
}
