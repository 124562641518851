'use client';

import React from 'react';
import { isMobile } from '@frontend/jetlend-web-ui/src/utils/responsive';
import dynamic from 'next/dynamic';
import { InvestorsChartType } from '@app/models/features/investors';
import {
    ChartMapType,
    ChartSectionType,
} from '@app/models/sections/chart';
import ChartSection from '@app/sections/ChartSection/ChartSection';

const LazyInvestorsNplChart = dynamic(() => import('@app/features/investors/InvestorsNplChart/InvestorsNplChart'), { ssr: false });

const CHARTS_MAP: ChartMapType<InvestorsChartType> = ({
    [InvestorsChartType.NPL_15]: (_, screenWidth) => (
        <LazyInvestorsNplChart
            height={isMobile(screenWidth) ? 250 : 430}
            hideLegend
            area
            noBorder
        />
    ),
});

export default function InvestorsChartSection(props: ChartSectionType<InvestorsChartType>) {
    return (
        <ChartSection {...props} charts={CHARTS_MAP} />
    );
}
