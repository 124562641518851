export enum Department {
    ALL = 'all',
    RISKS = 'risks',
    UNDERWRITING = 'underwriting',
    INVESTORS = 'investors',
    BORROWER = 'borrower',
    DEVELOPMENT = 'development',
    FINANCE = 'finance',
    MARKETING = 'marketing',
    LEGAL = 'legal',
    HR = 'hr',
}
