import { IClassName } from '@frontend/jetlend-core/src/models/common';
import { useResizeObserver } from '@frontend/jetlend-web-ui/src/hooks/useResizeObserver';
import React, {
    useMemo,
    useRef,
} from 'react';
import {
    Rect,
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryCursorContainer,
    VictoryLabel,
    VictoryPortal,
    VictoryTheme,
} from 'victory';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    isMobile,
    isSmallMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { useSelectionTooltipContainer } from '@frontend/jetlend-web-ui/src/ui/victory/SelectionTooltip/SelectionTooltip.logic';
import { formatMillionsAmount } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { ICompanyYearRevenueApiModel } from '@app/models/features/investments';
import styles from './CompanyRevenueBarChart.module.scss';
import ShapedArrow from './ShapedArrow';

export interface IProps extends IClassName {
    data: ICompanyYearRevenueApiModel[];
    arrowTitle?: React.ReactNode;
}

function getDomainPaddingX(columnWidth: number, screenWidth: number): number {
    if (isMobile(screenWidth)) {
        return columnWidth * 0.5;
    }

    if (isTablet(screenWidth)) {
        return columnWidth * 0.6;
    }

    return columnWidth;
}

export default function CompanyRevenueBarChart({
    data,
    arrowTitle,
    className: externalClassName,
}: IProps) {
    const containerRef = useRef<HTMLDivElement>();
    // Ширина и высота графика для адаптивности
    const [width, height] = useResizeObserver(containerRef);

    const { width: screenWidth } = useWindowSize();

    const isTabletOrMobile = isTablet(screenWidth) || isMobile(screenWidth);
    const barRatio = isMobile(screenWidth) ? 1.2 : 0.8;

    const columnWidth = (width / data.length) * Math.min(barRatio, 1.0);
    const victoryLabelFontSize = (() => {
        if (isSmallMobile(screenWidth)) {
            return '10px';
        }
        if (isMobile(screenWidth)) {
            return '14px';
        }

        return '16px';
    })();

    const convertedData = useMemo(() => {
        return data.map(item => ({
            x: item.year,
            y: item.amount,
            forecast: item.forecast,
        }));
    }, [ data ]);

    const selectionContainerProps = useSelectionTooltipContainer({
        lineWidth: columnWidth,
        data: convertedData,
        tooltipColorMarker: true,
        tooltipTitle: (selectedValue) => {
            if (selectedValue?.x === 2024) {
                return '9М 2024';
            }

            return selectedValue.forecast
                ? `${selectedValue?.x} (прогноз)`
                : selectedValue?.x.toString();
        },
        tooltipContent: selectedValue => {
            if (typeof selectedValue.y === 'object') {
                return [];
            }

            return [
                {
                    title: 'Выручка',
                    value: formatMillionsAmount(selectedValue.y),
                    color: '#2CAC8D',
                },
            ];
        },
    });

    const containerClassName = buildClassNames(styles, [
        'container',
    ], [ externalClassName ]);

    return (
        <div className={containerClassName} ref={containerRef}>
            <VictoryChart
                padding={{
                    top: 50,
                    left: 0,
                    right: 0,
                    bottom: 50,
                }}
                domainPadding={{
                    x: getDomainPaddingX(columnWidth, screenWidth),
                    y: 140,
                }}
                width={width}
                height={height}
                theme={VictoryTheme.clean}
                containerComponent={(
                    <VictoryCursorContainer {...selectionContainerProps} />
                )}
            >
                {/* Ось X */}
                <VictoryAxis
                    tickValues={data.map(item => item.year)}
                    tickFormat={tick => {
                        if (tick === 2024) {
                            const footnote = (() => {
                                if (isSmallMobile(screenWidth)) {
                                    return 8;
                                }
                                if (isMobile(screenWidth)) {
                                    return 10;
                                }

                                return 12;
                            })();

                            return [
                                '9М 2024',
                                <>
                                    LTM{' '}
                                    <tspan
                                        baselineShift="super"
                                        fill="#20836D"
                                        fontSize={footnote}
                                        fontFamily="inherit"
                                        fontWeight={600}
                                    >
                                        2
                                    </tspan>
                                </>,
                            ];
                        }

                        return tick.toString();
                    }}
                    style={{
                        axis: {
                            stroke: '#C1C1C1',
                            strokeWidth: 1,
                            shapeRendering: 'crispEdges',
                        },
                    }}
                    fixLabelOverlap
                    tickLabelComponent={(
                        <VictoryLabel
                            style={{
                                color: '#1E2021',
                                fontSize: victoryLabelFontSize,
                                fontWeight: 400,
                                fontFamily: 'inherit',
                            }}
                            textAnchor="middle"
                            lineHeight={[1, isTabletOrMobile ? 1.8 : 1.4]}
                        />
                    )}
                />

                {/* Группировка колонок */}
                <VictoryBar
                    alignment="middle"
                    cornerRadius={{ top: 8 }}
                    data={convertedData}
                    style={{
                        data: {
                            fill: '#2CAC8D',
                        },
                    }}
                    barRatio={barRatio}
                    labels={({ datum }) => (datum.y / 1000000).toFixed(1).replace('.', ',')}
                    labelComponent={(
                        <VictoryLabel
                            style={{
                                fill: '#1E2021B2',
                                fontSize: isTabletOrMobile ? '14px' : '18px',
                                fontWeight: 600,
                                fontFamily: 'inherit',
                            }}
                            verticalAnchor="end"
                            dy={-15}
                            backgroundPadding={[4, 6, 0, 6]}
                            backgroundStyle={{
                                fill: '#fff',
                                stroke: '#2CAC8D',
                                strokeWidth: 1,
                            }}
                            backgroundComponent={(
                                <Rect
                                    rx={8}
                                    ry={8}
                                />
                            )}
                        />
                    )}
                />

                {arrowTitle && (
                    <VictoryPortal>
                        <ShapedArrow
                            x1={data[0].year}
                            y1={data[0].amount}
                            x2={data[data.length - 1].year}
                            y2={data[data.length - 1].amount}
                            offset={40}
                            midY={50}
                            title={arrowTitle}
                        />
                    </VictoryPortal>
                )}
            </VictoryChart>
        </div>
    );
}
