import {
    CreditPortfolioApiModel,
    ICreditPortfolioChartValues,
} from '@app/models/features/investments';

const MEDIUM_BREAKPOINT = 1350;
const SMALL_BREAKPOINT = 1050;
export const EXTRA_SMALL_BREAKPOINT = 860;
const XX_SMALL_BREAKPOINT = 480;

/**
 * Получаем отступ графика от правого края по оси X
 * @param width ширина экрана
 */
export const getDomainPadding = (width: number) => {
    if (width <= XX_SMALL_BREAKPOINT) {
        return 10;
    }

    if (width <= SMALL_BREAKPOINT) {
        return 25;
    }

    if (width <= MEDIUM_BREAKPOINT) {
        return 30;
    }

    return 40;
};

/**
 * Получаем отступы графика со всех сторон относительно родительского компонента
 * @param width ширина экрана
 */
export const getChartPadding = (width: number) => {
    if (width <= XX_SMALL_BREAKPOINT) {
        return {
            left: 45,
            top: 30,
            right: 20,
            bottom: 40,
        };
    }

    if (width <= EXTRA_SMALL_BREAKPOINT) {
        return {
            left: 60,
            top: 40,
            right: 20,
            bottom: 40,
        };
    }

    return {
        left: 100,
        top: 60,
        right: 40,
        bottom: 60,
    };
};

/**
 * Получаем значение скругления колонок
 * @param width ширина экрана
 */
export const getBarCornerRadius = (width: number) => {
    if (width <= XX_SMALL_BREAKPOINT) {
        return 3;
    }

    if (width <= EXTRA_SMALL_BREAKPOINT) {
        return 4;
    }

    if (width <= MEDIUM_BREAKPOINT) {
        return 8;
    }

    return 12;
};

/**
 * Цвета графика
 */
const colors = [
    '#ccecf9',
    '#b7e4f7',
    '#addff6',
    '#98d8f2',
    '#84d1f1',
    '#7accf1',
    '#65c5ef',
    '#4FBAEA',
    '#3BB3E7',
    '#28ADE6',
    '#15A6E4',
    '#009EE2',
    '#009EE2',
    '#0099DA',
    '#008DC9',
    '#0087C1',
    '#007EB4',
    '#007AAF',
    '#0078AC',
    '#0073A5',
];

/**
 * Получаем отформатированные данные
 * @param data
 */
export const getFormattedData = (data: CreditPortfolioApiModel): ICreditPortfolioChartValues[] => {
    const result = [];

    data.forEach((item, index) => {
        result.push({
            title: item.start_date,
            x: index+1,
            y: item.amount,
            color: colors[index],
        });
    });

    return result;
};

/**
 * Получаем значения для оси Y
 * @param data
 */
export const getYAxisValues = (data: CreditPortfolioApiModel|undefined) => {
    if (!data) {
        return {
            values: [],
            maxValue: 0,
        };
    }
    const maxValue = Math.max(...data.map(item => item.amount));

    const result = [];
    for (let i = 0; i <= maxValue; i += 2) {
        result.push(i);
    }
    if (result[result.length - 1] < maxValue) {
        result.push(result[result.length - 1] + 2);
    }
    return {
        values: result,
        maxValue: result[result.length - 1],
    };
};

/**
 * Получаем значения, которые надо отображать на оси X
 * @param data
 * @param width
 */
export const getXAxisTickValues = (data: ICreditPortfolioChartValues[]|undefined) => {
    if (!data) {
        return [];
    }

    const maxValues = data.length;

    return Array.from({ length: maxValues }, (_, index) => index + 1);
};

/**
 * Получаем значения DY для корректного отображения лейблов
 * @param width
 */
export const getXAxisLabelsDY = (width: number) => {
    if (width <= EXTRA_SMALL_BREAKPOINT) {
        return [5];
    }

    return [18];
};
