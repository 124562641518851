import { useMemo } from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import {
    IInvestmentCalculatorChartPoint,
    useAutoInvestCalculatorForecast,
} from '@frontend/jetlend-core/src/features/AutoInvestCalculator/useAutoInvestCalculatorForecast';
import useStoreHandler from '@frontend/jetlend-core/src/hooks/useStoreHandler';
import { currentStrategyHandler } from '@app/ducks/autoinvest';
import {
    IInvestmentCalculatorFormValues,
    IInvestmentCalculatorResult,
} from '@app/models/features/investors';
import { investorsJetLendStatisticsHandler } from '@app/ducks/investors/investors';
import { IAutoInvestStrategyState } from '@app/models/common/autoinvest';

export interface IYieldCalculatorResult {
    /**
     * Минимальная ожидаемая доходность
     */
    minYield: number;
    /**
     * Максимальная ожидаемая доходность
     */
    maxYield: number;
    /**
     * Минимальный ожидаемый доход
     */
    minIncome: number;
    /**
     * Максимальный ожидаемый доход
     */
    maxIncome: number;
    /**
     * Минимальный ожидаемый размер капитала
     */
    minCapital: number;
    /**
     * Максимальный ожидаемый размер капитала
     */
    maxCapital: number;
}

function buildInvestmentSeries(baseCapitalAmount: number, monthlyTopUpAmount: number, baseYieldRate: number, monthNumber: number, extraVolatile = 0.0): IInvestmentCalculatorChartPoint[] {
    let minYieldRate: number;
    let maxYieldRate: number;

    if (baseYieldRate < 0) {
        maxYieldRate = baseYieldRate * (1 - baseYieldRate - extraVolatile);
        minYieldRate = baseYieldRate * (1 + baseYieldRate);
    } else {
        minYieldRate = baseYieldRate * (1 - baseYieldRate - extraVolatile);
        maxYieldRate = baseYieldRate * (1 + baseYieldRate);
    }

    const minYieldRateInMonth = minYieldRate / 12;
    const maxYieldRateInMonth = maxYieldRate / 12;

    const result: IInvestmentCalculatorChartPoint[] = [];

    let capital = baseCapitalAmount;
    let minCapital = capital + capital * minYieldRateInMonth;
    let maxCapital = capital + capital * maxYieldRateInMonth;
    let minIncome= capital * minYieldRateInMonth;
    let maxIncome = capital * maxYieldRateInMonth;

    result.push({
        month: 0,
        capital,
        minCapital,
        minIncome,
        maxIncome,
        maxCapital,
        profitRate: 0,
        minProfitRate: 0,
        maxProfitRate: 0,
    });

    for (let month = 1; month <= monthNumber; month++) {
        const isLowYear = month % 24 > 12;

        capital += capital * (isLowYear ? (minYieldRateInMonth - extraVolatile / 12) : (maxYieldRateInMonth + extraVolatile / 12)) + monthlyTopUpAmount;

        minCapital += minCapital * minYieldRateInMonth + monthlyTopUpAmount;
        maxCapital += maxCapital * maxYieldRateInMonth + monthlyTopUpAmount;
        minIncome = (capital + monthlyTopUpAmount*(month-1)) * minYieldRateInMonth + minIncome;
        maxIncome = (capital + monthlyTopUpAmount*(month-1)) * maxYieldRateInMonth + maxIncome;

        result.push({
            month,
            capital,
            minCapital,
            maxCapital,
            minIncome,
            maxIncome,
            profitRate: (capital - baseCapitalAmount - monthlyTopUpAmount * month) / baseCapitalAmount,
            minProfitRate: (minCapital - baseCapitalAmount - monthlyTopUpAmount * month) / baseCapitalAmount,
            maxProfitRate: (maxCapital - baseCapitalAmount - monthlyTopUpAmount * month) / baseCapitalAmount,
        });
    }

    return result;
}

/**
 * Хук с логикой расчета инвестиционного калькулятора
 *
 * @param initialAmount Первоначальная сумма инвестиций
 * @param monthlyTopUpAmount Сумма ежемесячного пополнения
 * @param termInYears Срок инвестиций
 * @returns Результаты вычислений (ожидаемая доходность, доход, размер капитала и значения для графика)
 */
export function useYieldCalculatorLogic({
    initialAmount,
    monthlyTopUpAmount,
    termInYears,
}: IInvestmentCalculatorFormValues): IInvestmentCalculatorResult {
    const [{ strategiesItemData }] = useStoreHandler<IAutoInvestStrategyState>(currentStrategyHandler);
    const [ jetlendStats ] = useSimpleApiHandler(investorsJetLendStatisticsHandler);
    const monthNumber = termInYears * 12;

    const {
        minIncome,
        maxIncome,
        minCapital,
        maxCapital,
        chart,
    } = useAutoInvestCalculatorForecast(
        initialAmount,
        monthlyTopUpAmount,
        termInYears,
        true,
        strategiesItemData?.expected_min_interest_rate,
        strategiesItemData?.expected_max_interest_rate
    );

    const calculatorResult = useMemo(() => ({
        minIncome,
        maxIncome,
        minExpectedYieldRate: strategiesItemData?.expected_min_interest_rate,
        maxExpectedYieldRate: strategiesItemData?.expected_max_interest_rate,
        minExpectedAmount: minCapital,
        maxExpectedAmount: maxCapital,

        termInYears,

        jetlendChart: chart,
        stocksChart: buildInvestmentSeries(initialAmount, monthlyTopUpAmount, jetlendStats?.stocks_expected_interest_rate, monthNumber, 0.3),
        bondsChart: buildInvestmentSeries(initialAmount, monthlyTopUpAmount, jetlendStats?.bond_expected_interest_rate, monthNumber, 0.1),
        depositsChart: buildInvestmentSeries(initialAmount, monthlyTopUpAmount, jetlendStats?.deposits_expected_interest_rate, monthNumber),
        realEstatesChart: buildInvestmentSeries(initialAmount, monthlyTopUpAmount, jetlendStats?.real_estates_expected_interest_rate, monthNumber),
    }), [
        initialAmount,
        monthlyTopUpAmount,
        termInYears,
        jetlendStats,
        strategiesItemData,
    ]);

    return calculatorResult;
}