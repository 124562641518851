import React from 'react';
import { InvestmentsSharesStepsFeatureType } from '@app/models/features/investments';
import styles from '@app/features/investments/InvestmentsSharesStepsSection/InvestmentsSharesStepsSection.module.scss';
import Image from '@app/components/Image/Image';
import HtmlMarkup from '@app/components/HtmlMarkup/HtmlMarkup';
import { IFeatureBlockApiModel } from '@app/models/sections/features';

/**
 * Блок с описанием шага инвестирования
 */
export default function InvestmentsSharesStepsBlock({
    title,
    image,
    description,
}: IFeatureBlockApiModel<InvestmentsSharesStepsFeatureType>) {
    return (
        <div className={styles['block__content']}>
            <div className={styles['block__header']}>
                <h3 className={styles['block__title']}>{title}</h3>
                <div className={styles['block__image']}>
                    <Image src={image} alt="" fill quality={100} />
                </div>
            </div>
            <div className={styles['block__description']}>
                <HtmlMarkup markup={description} tag="p" />
            </div>
        </div>
    );
}