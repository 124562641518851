'use client';

import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    ChangeEvent,
    MutableRefObject,
    useCallback,
    useRef,
    useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './ExpandControl.module.scss';
import rollupSectionStyles from '../RollupSection.module.scss';

interface IProps {
    /**
     * Класс для чекбокса
     */
    checkboxClassName?: string;

    /**
     * Класс для текста
     */
    labelClassName?: string;

    /**
     * Контейнер текста
     */
    wrapperRef: MutableRefObject<HTMLDivElement>;
}

/**
 * Компонент для работы с раскрытием текста
 */
export default function ExpandControl({
    checkboxClassName,
    labelClassName,
    wrapperRef,
}: IProps) {
    const controlRef = useRef<HTMLInputElement>(null);
    const [checked, setChecked] = useState(false);

    const inputId = `expand_control_${uuidv4()}`;

    const didChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!event?.target) {
            return;
        }

        setChecked(event.target.checked);
        const mainText = wrapperRef.current;

        if (!event.target.checked) {
            controlRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
            mainText.style.removeProperty('height');
            mainText.classList.add(rollupSectionStyles['shadow']);
            return;
        }

        mainText.classList.remove(rollupSectionStyles['shadow']);
        mainText.style.height = `${mainText.scrollHeight}px`;
    }, [wrapperRef]);

    return (
        <>
            <label
                className={mergeClassNames([styles['label'], labelClassName])}
                htmlFor={inputId}
            >
                {checked ? 'Свернуть' : 'Развернуть'}
            </label>
            <input
                className={mergeClassNames([styles['checkbox'], checkboxClassName])}
                type="checkbox"
                checked={checked}
                onChange={didChanged}
                ref={controlRef}
                id={inputId}
            />
        </>
    );
};
