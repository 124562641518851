'use client';

import React, {
    ReactNode,
    useRef,
} from 'react';
import dynamic from 'next/dynamic';
import { SlideProps } from 'react-slideshow-image';
import {
    isDesktop,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import OffscreenLoader from '@app/ui/OffscreenLoader/OffscreenLoader';
import './slider.scss';
import styles from './AcademyNewsSlider.module.scss';

const LazySlide = dynamic(() => import('react-slick').then(module => module), { ssr: false });

const RESPONSIVE_SETTINGS = [
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 568,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
];

const NextArrow = (
    <div className={mergeClassNames([styles['arrows'], styles['arrow-right']])}>
        <svg viewBox="0 0 100 100">
            <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#333" transform="translate(100, 100) rotate(180) " />
        </svg>
    </div>
);

const PrevArrow = (
    <div className={mergeClassNames([styles['arrows'], styles['arrow-left']])}>
        <svg viewBox="0 0 100 100">
            <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#333" />
        </svg>
    </div>
);

export interface IProps {
    arrows?: boolean;
    children: ReactNode[];
}

/**
 * Компонент для отрисовки слайдера статей по академии
 */
export default function AcademyNewsSlider({
    children,
    arrows,
}: IProps) {
    const sliderRef = useRef<SlideProps | null>(null);
    const { width: screenWidth } = useWindowSize();
    const isDesktopSize = isDesktop(screenWidth);

    const SLIDER_SETTINGS = {
        children: [],
        arrows: arrows && isDesktopSize,
        indicators: false,
        autoplay: false,
        infinite: false,
        duration: 1200,
        transitionDuration: 400,
        className: 'academy-news-slider',
        canSwipe: true,
        ref: sliderRef,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: RESPONSIVE_SETTINGS,
        nextArrow: NextArrow,
        prevArrow: PrevArrow,
    };

    return (
        <OffscreenLoader>
            {({
                ref,
                inView,
            }) => (
                <div ref={ref}>
                    {inView && (
                        <LazySlide {...SLIDER_SETTINGS}>
                            {children?.map((child, i) => (
                                <React.Fragment key={i}>
                                    <div className={styles['item-wrapper']}>{child}</div>
                                </React.Fragment>
                            ))}
                        </LazySlide>
                    )}
                </div>
            )}
        </OffscreenLoader>
    );
};
