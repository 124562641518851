'use client';

import React, { Suspense } from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import dynamic from 'next/dynamic';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import SectionPart from '@app/ui/SectionPart/SectionPart';
import styles from './BorrowersCalculatorSection.module.scss';
import { ISectionBasedProps } from '@app/models/common/common';
import OffscreenLoader from '@app/ui/OffscreenLoader/OffscreenLoader';
import BorrowersCalculatorForm from './BorrowersCalculatorForm/BorrowersCalculatorForm';
import BorrowersCalculatorSummary from './BorrowersCalculatorSummary/BorrowersCalculatorSummary';
import BorrowersCalculatorApply from './BorrowersCalculatorApply/BorrowersCalculatorApply';

interface IProps extends ISectionBasedProps<{title: string}> {
    /**
     * Текст на лид кнопке
     */
    leadButtonText?: string;
}

const LazyBorrowersCalculatorChart = dynamic(() => import('./BorrowersCalculatorChart/BorrowersCalculatorChart'), { ssr: false });

const BorrowersCalculatorSection = (props: IProps) => {
    const { section: { title } } = props;

    return (
        <div className={styles['container']} data-anchor="calculator">
            <SectionPart className={buildClassNames(styles, ['block', 'calc'])}>
                <BorrowersCalculatorForm title={title} />
            </SectionPart>
            <SectionPart className={buildClassNames(styles, ['block', 'chart'])}>
                <OffscreenLoader>
                    {({
                        inView,
                        ref,
                    }) => (
                        <div ref={ref}>
                            <Suspense fallback={<LoaderBlock />}>
                                {inView &&
                                    <LazyBorrowersCalculatorChart />
                                }
                            </Suspense>
                        </div>
                    )}
                </OffscreenLoader>
            </SectionPart>
            <SectionPart className={buildClassNames(styles, ['block', 'sum'])}>
                <BorrowersCalculatorSummary />
            </SectionPart>
            <SectionPart className={buildClassNames(styles, ['block', 'apply'])}>
                <BorrowersCalculatorApply leadButtonText={props.leadButtonText} />
            </SectionPart>
        </div>
    );
};

export default BorrowersCalculatorSection;
