'use client';

import React, { Suspense } from 'react';
import { useWindowSize } from '@frontend/jetlend-web-ui/src/utils/responsive';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import OffscreenLoader from '@app/ui/OffscreenLoader/OffscreenLoader';
import styles from './ChartSection.module.scss';
import {
    ChartMapType,
    ChartSectionType,
} from '@app/models/sections/chart';

export interface IProps<ChartId extends string> extends ChartSectionType<ChartId> {
    charts: ChartMapType<ChartId>;
    withoutDescription?: boolean;
    withoutPadding?: boolean;
}

export default function ChartSection<ChartId extends string>({
    section,
    charts,
    withoutDescription,
    withoutPadding,
}: IProps<ChartId>) {
    const { width: screenWidth } = useWindowSize();

    const chartClassNames = buildClassNames(styles, [
        'chart',
        `chart--${section.id}`,
        withoutPadding && 'chart--without-padding',
    ]);

    return (
        <div>
            {section.header_description && (
                <div className={styles['name']}>
                    {section.header_description}
                </div>
            )}
            <div className={styles['shadow-container']}>
                <OffscreenLoader>
                    {({
                        inView,
                        ref,
                    }) => (
                        <div ref={ref} className={chartClassNames}>
                            <Suspense
                                fallback={(
                                    <LoaderBlock />
                                )}
                            >
                                {inView && charts[section.id](section, screenWidth)}
                            </Suspense>
                        </div>
                    )}
                </OffscreenLoader>
            </div>
            {!withoutDescription && section.description && (
                <div className={styles['description']}>
                    {section.description}
                </div>
            )}
        </div>
    );
}
