'use client';

import React from 'react';
import InvestmentsMarketShareChart from '@app/features/investments/InvestmentsMarketShareChart/InvestmentsMarketShareChart';
import InvestmentsBusinessAreasChart from '@app/features/investments/InvestmentsBusinessAreasChart/InvestmentsBusinessAreasChart';
import InvestmentsCreditPortfolioChart from '@app/features/investments/InvestmentsCreditPortfolio/InvestmentsCreditPortfolioChart';
import { InvestmentsChartType } from '@app/models/features/investments';
import InvestmentsCompanyRevenueChart from '../InvestmentsCompanyRevenueChart/InvestmentsCompanyRevenueChart';
import {
    ChartMapType,
    ChartSectionType,
} from '@app/models/sections/chart';
import ChartSection from '@app/sections/ChartSection/ChartSection';

/**
 * Типы графиков
 */
const CHARTS_MAP: ChartMapType<InvestmentsChartType> = ({
    [InvestmentsChartType.MARKET_SHARE]: section => (
        <InvestmentsMarketShareChart section={section} />
    ),
    [InvestmentsChartType.BUSINESS_AREAS]: section => (
        <InvestmentsBusinessAreasChart section={section} />
    ),
    [InvestmentsChartType.CREDIT_PORTFOLIO]: section => (
        <InvestmentsCreditPortfolioChart section={section} />
    ),
    [InvestmentsChartType.COMPANY_REVENUE]: section => (
        <InvestmentsCompanyRevenueChart section={section} />
    ),
});

/**
 * Секция для отрисовки различных графиков
 */
export default function InvestmentsChartSection(props: ChartSectionType<InvestmentsChartType>) {
    return (
        <ChartSection
            {...props}
            charts={CHARTS_MAP}
            withoutDescription
            withoutPadding
        />
    );
}

