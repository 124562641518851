'use client';

import { useMemo } from 'react';
import HistoryFeature from '@app/components/HistoryFeature/HistoryFeature';
import YieldRateFeature from './YieldRateFeature/YieldRateFeature';
import ConditionsFeature from './ConditionsFeature/ConditionsFeature';
import AdvantagesFeature from './AdvantagesFeature/AdvantagesFeature';
import LinksFeature from './LinksFeature/LinksFeature';
import {
    FeatureSectionType,
    getSectionType,
} from './MainPageFeaturesSection.logic';
import { FeaturesSectionType } from '@app/models/sections/features';

export default function MainPageFeaturesSection({ section }: FeaturesSectionType) {
    const sectionType = getSectionType(section);
    const config = useMemo<Record<FeatureSectionType, JSX.Element | null>>(() => ({
        'chart': <YieldRateFeature section={section} />,
        'conditions': <ConditionsFeature section={section} />,
        'advantages': <AdvantagesFeature section={section} />,
        'history': <HistoryFeature section={section} />,
        'links': <LinksFeature section={section} />,
        'null': null,
    }), [section]);

    return config[sectionType];
}
