'use client';

import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import React, { useCallback } from 'react';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import iconCircleArrow from '@frontend/jetlend-assets/icons/icon--circle-arrow.svg';
import { IBrokerModel } from '@app/models/common/common';
import styles from './BrokerCard.module.scss';
import Image from '@app/components/Image/Image';
import HtmlMarkup from '@app/components/HtmlMarkup/HtmlMarkup';
import { sendEvent } from '@app/ducks/common/analytics';

export interface IProps {
    /**
     * Модель брокера
     */
    broker?: IBrokerModel;
}

/**
 * Карточка брокера
 */
export default function BrokerCard({
    broker,
}: IProps) {
    const sendEventAction = useActions(sendEvent);
    const didClicked = useCallback(() => {
        sendEventAction(`investments--broker-${broker?.id}-click`);
    }, [ broker?.id, sendEventAction ]);

    if (!broker) {
        return (
            <div className={styles['broker-void']}>
                <div className={styles['broker-void__icon']}>
                    <Image
                        src={iconCircleArrow}
                        alt=""
                        fill
                    />
                </div>
                <p className={styles['broker-void__text']}>Здесь будет отображаться брокер</p>
            </div>
        );
    }

    return (
        <div className={styles['broker']}>
            <div className={styles['broker__header']}>
                {broker?.icon && (
                    <div className={styles['broker__icon']}>
                        <Image
                            src={broker?.icon}
                            alt=""
                            fill
                        />
                    </div>
                )}
                <HtmlMarkup
                    tag="p"
                    className={styles['broker__title']}
                    markup={broker?.title}
                />
            </div>
            <Button
                external
                onClick={didClicked}
                href={broker?.url}
                text="Перейти к брокеру"
                className={styles['broker__button']}
                containerClassName={styles['broker__button-container']}
            />
        </div>
    );
}