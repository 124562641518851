'use client';

import useObjectHandler from '@frontend/jetlend-core/src/hooks/useObjectHandler';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    documentsPageStateHandler,
    updateDocuments,
} from '@app/ducks/documents/documents';
import DocumentCard from '../DocumentCard/DocumentCard';
import DocumentsNotFound from '../DocumentsNotFound/DocumentsNotFound';

import styles from './DocumentsList.module.scss';
import { IDocumentApiModel } from '@app/models/sections/documents';

export interface IProps {
    /**
     * Список документов.
     */
    documents: IDocumentApiModel[];
    /**
     * Разрешена ли фильтрация документов.
     */
    allowFilter?: boolean;
}

/**
 * Компонент отображающий список документов.
 */
export default function DocumentsList({
    documents,
    allowFilter = false,
}: IProps) {
    const dispatch = useDispatch();

    // TODO Переписать поиск и фильтрацию документов
    // Вынести это на уровень пропсов и не хранить список документов в стейте, хранить в стейте только фильтры и поиск
    useLayoutEffect(() => {
        if (!allowFilter) {
            return;
        }

        dispatch(updateDocuments(documents));
    }, [dispatch, documents, allowFilter]);

    const [ state ] = useObjectHandler(documentsPageStateHandler);
    const filteredDocuments = allowFilter
        ? state.filteredDocuments
        : documents;

    const wasDocumentsFound = filteredDocuments?.length > 0;

    return (
        wasDocumentsFound
            ? (filteredDocuments?.map((document, index) => (
                <DocumentCard
                    className={styles['card']}
                    key={`${document?.file?.url ?? ''}-${index}`}
                    document={document}
                />
            )))
            : <DocumentsNotFound className={styles['not-found']} />
    );
};
