import React, { forwardRef } from 'react';

export interface IProps<T> extends React.HTMLAttributes<T> {
    tag?: keyof React.ReactHTML;
    className?: string;
    markup?: string;
}

const HtmlMarkup = forwardRef<HTMLElement, IProps<HTMLElement>>(({
    tag = 'div',
    markup,
    ...rest
}, ref) => {
    return React.createElement(tag, {
        ...rest,
        ref,
        dangerouslySetInnerHTML: {
            __html: markup || '',
        },
    });
});

export default HtmlMarkup;
