import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { ReactNode } from 'react';
import styles from './HistoryEvent.module.scss';

interface IProps {
    year: string;
    title: ReactNode;
    titleDescription?: string;
    description: string;
    isLast: boolean;
}

export default function HistoryEvent({
    year,
    title,
    titleDescription,
    description,
    isLast,
}: IProps) {
    return (
        <div className={styles['event']}>
            <div className={buildClassNames(styles, ['year', isLast && 'year--last'])}>{year}</div>
            <div className={styles['title-wrapper']}>
                <div className={styles['title']}>{title}</div>
                {titleDescription && <div className={styles['title-description']}>{titleDescription}</div>}
            </div>
            <div className={styles['description']}>{description}</div>
            <div className={styles['line-container']}>
                <div className={styles['history-point']} />
                <div className={buildClassNames(styles, ['event-line', isLast && 'event-line--tail'])} />
            </div>
        </div>
    );
};
