import React from 'react';
import moment from 'moment';
import styles from './InvestmentsCreditPortfolioChartAxisTick.module.scss';
import {
    EXTRA_SMALL_BREAKPOINT,
    getXAxisLabelsDY,
} from '@app/features/investments/InvestmentsCreditPortfolio/InvestmentsCreditPortfolioChart.logic';
import { ICreditPortfolioChartValues } from '@app/models/features/investments';

interface IProps {
    /**
     * Координата X
     */
    x: number;

    /**
     * Координата Y
     */
    y: number;

    /**
     * Значение в точке
     */
    datum: number;

    /**
     * Если true — ось Y, если false — ось X
     */
    dependentAxis?: boolean;

    /**
     * Список годов, для отображения на мобилке
     */
    years?: string[];

    /**
     * Ширина экрана
     */
    width?: number;

    /**
     * Количество кварталов в последнем году
     */
    quartersCount?: number;

    /**
     * Данные графика
     */
    data?: ICreditPortfolioChartValues[];
}

/**
 * Элемент оси X или Y, который отвечает за отображение значений в заданной точке
 */
export default function InvestmentsCreditPortfolioChartAxisTick({
    x,
    y,
    datum,
    dependentAxis,
    width,
    data,
}: Partial<IProps>) {
    // X Axis
    if (!dependentAxis) {
        const currentBar = data?.find(item => item.x === datum);

        const quarter = moment(currentBar?.title).quarter();
        const year = moment(currentBar?.title).year();
        const formattedYear = String(year).slice(2);

        const dy = getXAxisLabelsDY(width);

        return (
            <text
                x={x}
                y={y}
                dy={dy[0]}
                className={styles['x-axis']}
                textAnchor="middle"
            >
                {quarter}K {formattedYear}г
            </text>
        );
    }

    // Y Axis
    return (
        <>
            {width > EXTRA_SMALL_BREAKPOINT && (
                <rect
                    x={x}
                    y={y}
                    className={styles['rect']}
                    rx={8}
                />
            )}
            <text
                dx="0"
                x={x}
                y={y}
                className={styles['value']}
                textAnchor="middle"
            >
                {datum}
            </text>
            <text
                dx="0"
                x={x}
                y={y}
                className={styles['text']}
            >
                млрд
            </text>
        </>
    );
};
