import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import Range, { NumberValuesRange } from '@frontend/jetlend-web-ui/src/ui/inputs/Range/Range';
import { FieldAmountValueConverter } from '@frontend/jetlend-core/src/fields/amountFieldConverter';
import { formatAmountShortText } from '@frontend/jetlend-core/src/formatters/formatUtils';
import {
    numWordForm,
    wordForm,
} from '@frontend/jetlend-core/src/utils/langUtils';
import { IFieldValueConverter } from '@frontend/jetlend-core/src/fields/common';
import { withDebounce } from '@frontend/jetlend-core/src/async/debounce';
import { useRangeNumberEditorHandler } from '@frontend/jetlend-web-ui/src/ui/inputs/Range/utils';
import {
    isMobile,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import useObjectHandler from '@frontend/jetlend-core/src/hooks/useObjectHandler';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import styles from '@app/features/investors/InvestmentCalculator/InvestmentCalculator.module.scss';
import {
    getPortfolioRangesHandler,
    portfolioRangeHandler,
} from '@app/ducks/autoinvest';
import { sendEvent as sendEventAction } from '@app/ducks/common/analytics';
import { investmentCalculatorHandler } from '@app/ducks/investors/investors';
import { IInvestmentCalculatorResult } from '@app/models/features/investors';
import { useYieldCalculatorLogic } from '@app/hooks/common/useYieldCalculatorLogic';


const INITIAL_AMOUNT_RANGE_CONFIG: NumberValuesRange = {
    min: 10000,
    max: 10000000,
    step: 1000,
    labelFormatter: v => formatAmountShortText(v),
};

const MONTHLY_TOPUP_AMOUNT_RANGE_CONFIG: NumberValuesRange = {
    min: 0,
    max: 1000000,
    step: 10000,
    labelFormatter: v => formatAmountShortText(v),
};

const TERM_RANGE_CONFIG: NumberValuesRange = {
    min: 1,
    max: 10,
    step: 1,
    labelFormatter: v => numWordForm(v, 'year'),
};

/**
* Компонент с настройками инвестиционного калькулятора
*/
const InvestmentCalculatorForm = () => {
    const [, setResult] = useObjectHandler(investmentCalculatorHandler);

    const [initialAmount, setInitialAmount] = useState(3000000);
    const [monthlyTopUpAmount, setMonthlyTopUpAmount] = useState(50000);
    const [termInYears, setTermInYears] = useState(10);

    const [ranges] = useSimpleApiHandler(getPortfolioRangesHandler);
    const [rangeState, updateRangeState] = useObjectHandler(portfolioRangeHandler);

    const sendEvent = useActions(sendEventAction);
    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);

    const TERM_EDITOR_CONFIG = useMemo<IFieldValueConverter>(() => ({
        ...FieldAmountValueConverter,
        postfix: `${wordForm(termInYears, 'year')}`,
    }), [termInYears]);

    const didChanged = useMemo(() => {
        const debouncedSendEvent = withDebounce(sendEvent);

        return () => {
            debouncedSendEvent('investor-calculator--changed');
        };
    }, []);

    const didInitialAmountChanged = useCallback(v => {
        const range = ranges?.find(item => {
            if (!item.max_amount) {
                return item.min_amount < v;
            }

            return item.min_amount <= v && item.max_amount > v;
        });

        if (range?.id !== rangeState.rangeId) {
            updateRangeState({ rangeId: range?.id });
        }
        setInitialAmount(v);
        didChanged();
    }, [ranges, rangeState]);

    const didMonthlyTopUpAmountChanged = useCallback(v => {
        setMonthlyTopUpAmount(v);
        didChanged();
    }, []);

    const didTermInYearsChanged = useCallback(v => {
        setTermInYears(v);
        didChanged();
    }, []);

    const initialAmountRangeEditorHandler = useMemo(
        // useRangeNumberEditorHandler is not a React Hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        () => useRangeNumberEditorHandler(INITIAL_AMOUNT_RANGE_CONFIG, { minStep: 1000 }),
        []
    );

    const monthlyTopUpAmountRangeEditorHandler = useMemo(
        // useRangeNumberEditorHandler is not a React Hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        () => useRangeNumberEditorHandler(MONTHLY_TOPUP_AMOUNT_RANGE_CONFIG, { minStep: 10000 }),
        []
    );

    const termInYearsRangeEditorHandler = useMemo(
        // useRangeNumberEditorHandler is not a React Hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        () => useRangeNumberEditorHandler(TERM_RANGE_CONFIG),
        []
    );

    const result = useYieldCalculatorLogic({
        initialAmount,
        monthlyTopUpAmount,
        termInYears,
    });

    const factory = useCallback(isMobileScreen
        ? withDebounce((values: IInvestmentCalculatorResult) => setResult({ result: values }), { timeout: 500 })
        : (values: IInvestmentCalculatorResult) => setResult({ result: values })
    , [isMobileScreen]);

    useEffect(() => {
        factory(result);
    }, [factory, result]);

    return (
        <>
            <div className={styles['range-block']}>
                <div className={styles['range-container']}>
                    <div className={styles['form-label']}>
                        Первоначальная сумма инвестиций
                    </div>
                    <Range
                        white
                        withoutBorder
                        range={INITIAL_AMOUNT_RANGE_CONFIG}
                        value={initialAmount}
                        onChange={didInitialAmountChanged}
                        editable
                        editorConfig={FieldAmountValueConverter}
                        onEditComplete={initialAmountRangeEditorHandler}
                        labelRender={() => (
                            <div className={styles['range-label']}>
                                {formatAmountShortText(initialAmount)}
                            </div>
                        )}
                        containerClassName={styles['range']}
                    />
                </div>
                <div className={styles['range-container']}>
                    <div className={styles['form-label']}>
                        Сумма ежемесячного пополнения
                    </div>
                    <Range
                        white
                        withoutBorder
                        range={MONTHLY_TOPUP_AMOUNT_RANGE_CONFIG}
                        value={monthlyTopUpAmount}
                        onChange={didMonthlyTopUpAmountChanged}
                        editable
                        editorConfig={FieldAmountValueConverter}
                        onEditComplete={monthlyTopUpAmountRangeEditorHandler}
                        labelRender={() => (
                            <div
                                className={styles['range-label']}
                            >{formatAmountShortText(monthlyTopUpAmount)}
                            </div>
                        )}
                        containerClassName={styles['range']}
                    />
                </div>
            </div>
            <div className={styles['range-container']}>
                <div className={styles['form-label']}>
                    Срок инвестиций
                </div>
                <Range
                    white
                    withoutBorder
                    range={TERM_RANGE_CONFIG}
                    value={termInYears}
                    onChange={didTermInYearsChanged}
                    editable
                    editorConfig={TERM_EDITOR_CONFIG}
                    onEditComplete={termInYearsRangeEditorHandler}
                    labelRender={() => (
                        <div className={styles['range-label']}>
                            {numWordForm(termInYears, 'year')}
                        </div>
                    )}
                    containerClassName={styles['range']}
                />
            </div>
        </>
    );
};

export default InvestmentCalculatorForm;
