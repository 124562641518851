import { CURRENCY_RUB } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './HistoryFeature.module.scss';
import HistorySlider from './HistorySlider/HistorySlider';
import HistoryEvent from './HistoryEvent/HistoryEvent';
import { FeaturesSectionType } from '@app/models/sections/features';

const EVENT_INDEXES_WITH_CURRENCY_POSTFIX = [1, 3];

const splitPhraseToWords = (phrase: string) => {
    const words = phrase.split(' ');
    return words.map((word, index, array) => (
        <span key={index} className={buildClassNames(styles, ['value', word === CURRENCY_RUB && 'value--postfix'])}>
            {word}
            {index !== array.length - 1 && ' '}
        </span>
    ));
};

export default function HistoryFeature({ section }: FeaturesSectionType) {
    return (
        <div className={styles['feature']}>
            <h2 className={styles['feature-title']}>{section.title}</h2>
            <div className={styles['history']}>
                {section.blocks.map((block, index, array) => (
                    <HistoryEvent
                        key={block.id}
                        year={block.value}
                        title={EVENT_INDEXES_WITH_CURRENCY_POSTFIX.includes(index) ? splitPhraseToWords(block.title) : block.title}
                        titleDescription={block.value_description}
                        description={block.description}
                        isLast={index === array.length - 1}
                    />
                ))}
                <div className={styles['history-line']}>
                    <div className={styles['line-tale']} />
                </div>
            </div>
            <div className={styles['slider-wrapper']}>
                <div className={styles['slider']}>
                    <HistorySlider showNextSlide>
                        {section.blocks.map((block, index, array) => (
                            <HistoryEvent
                                key={block.id}
                                year={block.value}
                                title={EVENT_INDEXES_WITH_CURRENCY_POSTFIX.includes(index) ? splitPhraseToWords(block.title) : block.title}
                                titleDescription={block.value_description}
                                description={block.description}
                                isLast={index === array.length - 1}
                            />
                        ))}
                    </HistorySlider>
                </div>
            </div>
        </div>
    );
}
