import {
    formatPercentExt,
    formatPercentLong,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import {
    IExpectedYieldValuesItem,
    SigmaItem,
} from '@app/models/common/autoinvest';
import styles from './strategyExpectedYieldChart.module.scss';

const sigmaTitles = ['-3σ', '-2σ', '-1σ', 'μ', '1σ', '2σ', '3σ'];

/**
 * Функция для получения координаты средней сигмы
 *
 * @param sigma массив сигм
 *
 */
export const getAverageSigma = (sigma: SigmaItem[]) => sigma[(sigma.length-1)/2].x;
export const getNotEmptyData = (data: IExpectedYieldValuesItem[]) => data?.filter(item => item.yield_rate);

/**
 * Функция для построения аннотаций (вертикальных линий и подписей сверху)
 *
 * @param data массив сигм
 * @param isMobile флаг, определяющий мобильную разметку
 * @param line флаг, определяющий вид аннотаций (если true - строятся вертикальные линии, если нет надписи)
 *
 */
export const buildXAxisAnnotationForAxisLabel = (data: SigmaItem[], isMobile: boolean, line = true) => data.map((item, index, array) => {
    const averageIndex = (array.length-1)/2;
    const diff = array[array.length-1].x - array[0].x;
    const lowDiff = diff < 0.2;
    const isMobileTitleHidden = isMobile && (lowDiff && index === averageIndex-2 || lowDiff && index === averageIndex+2);
    const isTitleHidden = index === averageIndex-1 || index === averageIndex+1;
    const borderColor = line ? '#09362C9E' : 'transparent';
    const strokeDashArray = line ? 10 : 0;
    const xAxisAnnotationsLabel = isTitleHidden || isMobileTitleHidden ?  '' : formatPercentExt(item.yield_rate);
    const text = line ? sigmaTitles[index] : xAxisAnnotationsLabel;
    const offsetY = line ? -9 : 18;
    const cssClass = line ? styles['sigma-label'] : styles['axis-text'];
    const position = line ? 'top' : 'bottom';

    return {
        x: item.x,
        borderColor,
        strokeDashArray,
        label: {
            text,
            orientation: 'horizontal',
            position,
            borderWidth: 0,
            offsetY,
            style: {
                cssClass,
                fontFamily: 'SF Pro Display, sans-serif',
                background: 'transparent',
                fontSize: isMobile ? '8px' : '12px',
            },
        },
    };
});

/**
 * Функция для создания подписей по оси X.
 *
 * @param data данные по оси х
 * @param width ширина контейнера
 *
 */
export const buildXAxisLabel = (data: IExpectedYieldValuesItem[], width: number) => data.map((item, index, array) => {
    const isNotShowText = width < 920 && index % 2 === 0;

    return {
        x: item.x,
        borderColor: 'transparent',
        opacity: 0.3,
        label: {
            text: isNotShowText ? '' : formatPercentLong(item.yield_rate),
            orientation: 'horizontal',
            position: 'bottom',
            borderWidth: 0,
            offsetY: 15,
            style: {
                fontFamily: 'SF Pro Display, sans-serif',
                background: 'transparent',
                fontSize: width < 568 ? '8px' : '12px',
            },
        },
    };
});

export const getAnnotationsArray = (
    data: SigmaItem[],
    isMobile: boolean
) => [
    buildXAxisAnnotationForAxisLabel(data, isMobile),
];