'use client';

import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import React, { useCallback } from 'react';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import styles from './BecomeInvestmentForm.module.scss';
import { sendEvent } from '@app/ducks/common/analytics';
import { investmentsBrokersModalHandler } from '@app/ducks/investments';

export interface IProps {
    /**
     * Название типа секции, которое будет использовать для аналитики.
     */
    sectionType?: string;
    /**
     * Текст кнопки.
     */
    buttonText?: string;
}

/**
 * Лид кнопка "Оставить заявку" для страниц Акционерам для оставления заявки у брокеров.
 */
export default function BecomeInvestmentForm({
    sectionType,
    buttonText = 'Оставить заявку',
}: IProps) {
    const sendEventAction = useActions(sendEvent);
    const openBrokersModal = useActions(investmentsBrokersModalHandler.open);

    const didButtonClicked = useCallback(() => {
        sendEventAction('investments-button');
        sendEventAction('investments-button--simple');

        if (sectionType) {
            sendEventAction(`investments--button--${sectionType}`);
        }

        openBrokersModal();
    }, [ sendEventAction, openBrokersModal, sectionType ]);

    return (
        <Button
            text={buttonText}
            size="big"
            onClick={didButtonClicked}
            width={250}
            noSpacing
            containerClassName={styles['buttonContainer']}
        />
    );
}