'use client';

import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import CompaniesSection from '@app/sections/CompaniesSection/CompaniesSection';
import { investorsCompaniesHandler } from '@app/ducks/investors/investors';

export default function InvestorsCompaniesSection() {
    const [ companies ] = useSimpleApiHandler(investorsCompaniesHandler);

    return (
        <CompaniesSection companies={companies} />
    );
};