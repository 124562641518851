import {
    CURRENCY_RUB,
    formatAmountWithPostfix,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import { IChartDataType } from '@app/models/features/investments';
import {
    CHART_SIZE,
    IProps,
} from '@app/components/PieChartBlock/PieChart/PieChart';
import styles from './PieChartInnerComponent.module.scss';

function formatChartValue(value: number, type: IChartDataType) {
    switch (type) {
    case IChartDataType.AMOUNT:
        return formatAmountWithPostfix(value, 1, CURRENCY_RUB, false);
    case IChartDataType.COUNT:
        return formatAmountWithPostfix(value, 0, '', false);
    default:
        return value;
    }
}

/**
 * Внутренний компонент кругового графика для отображения суммы значений параметров графика
 */
export default function PieChartInnerComponent({
    section,
    chartData,
}: IProps) {
    const formattedTotalValue = formatChartValue(chartData?.total, chartData?.type);
    return (
        <svg
            viewBox={`0 0 ${CHART_SIZE} ${CHART_SIZE}`}
        >
            <switch>
                <foreignObject
                    x={CHART_SIZE / 4}
                    y={CHART_SIZE / 2.5}
                    width={CHART_SIZE / 2}
                    height={CHART_SIZE / 4}
                >
                    <p className={styles['value']}>
                        {formattedTotalValue}
                    </p>
                    <p className={styles['description']}>
                        {section.description}
                    </p>
                </foreignObject>
            </switch>
            <circle
                cx={CHART_SIZE / 2}
                cy={CHART_SIZE / 2}
                r={CHART_SIZE / 3.8}
                stroke="#454459"
                strokeWidth="4"
                fill="none"
                opacity="0.05"
            />
        </svg>
    );
}