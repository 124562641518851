'use client';

import React from 'react';
import { formatDate } from '@frontend/jetlend-core/src/formatters/formatUtils';
import Image from '@app/components/Image/Image';
import styles from './YouTubeItem.module.scss';
import { IYouTubeItemApiModel } from '@app/models/common/common';

export default function YouTubeItem({
    url,
    date,
    title,
    preview_url,
    author,
}: IYouTubeItemApiModel) {
    return (
        <div
            key={url}
            className={styles['container']}
        >
            <div className={styles['img']}>
                <Image
                    src={preview_url}
                    alt=""
                    className={styles['preview']}
                    fill
                />
                <Image
                    className={styles['icon']}
                    src="/next/assets/yt-icon.svg"
                    alt=""
                    width={60}
                    height={60}
                />
            </div>
            <div className={styles['content']}>
                <div className={styles['top-block']}>
                    <div className={styles['date']}>{formatDate(date)}</div>
                    <h3 className={styles['title']}>{title}</h3>
                </div>
                {author && (
                    <div className={styles['bottom-block']}>
                        <Image
                            className={styles['author-icon']}
                            src={author.icon}
                            alt=""
                            width={44}
                            height={44}
                        />
                        <div className={styles['name']}>{author.name}</div>
                        {author.verified && (
                            <Image
                                src="/next/assets/verified-icon.svg"
                                alt=""
                                width={12}
                                height={12}
                                unoptimized
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
