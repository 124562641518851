const RESPONSIVE_SETTINGS = [
    {
        breakpoint: 4000,
        settings: {
            slidesToShow: 1.25,
            slidesToScroll: 0.5,
        },
    },
    {
        breakpoint: 3000,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 0.5,
        },
    },
    {
        breakpoint: 1600,
        settings: {
            slidesToShow: 0.75,
            slidesToScroll: 0.5,
        },
    },
    {
        breakpoint: 1100,
        settings: {
            slidesToShow: 0.5,
            slidesToScroll: 0.5,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 0.25,
            slidesToScroll: 0.25,
        },
    },
];

export function getSettings(amount: number) {
    const toInteger = (value: number) => Math.trunc(amount * value) || 1;

    return RESPONSIVE_SETTINGS.map(item => ({
        ...item,
        settings: {
            slidesToShow: toInteger(item.settings.slidesToShow),
            slidesToScroll: toInteger(item.settings.slidesToScroll),
        },
    }));
}
