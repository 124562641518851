'use client';

import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useResizeObserver } from '@frontend/jetlend-web-ui/src/hooks/useResizeObserver';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './DirectorsSection.module.scss';
import HtmlMarkup from '@app/components/HtmlMarkup/HtmlMarkup';
import DirectorCard from './DirectorCard/DirectorCard';
import { manageGradients } from './directorsSection.logic';
import { DirectorsSectionType } from '@app/models/sections/directors';

export default function DirectorsSection({ section }: DirectorsSectionType) {
    const persons = section.others;

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [hasRightGradient, setHasRightGradient] = useState(false);
    const [hasLeftGradient, setHasLeftGradient] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const directorsRef = useRef<HTMLDivElement>(null);

    useResizeObserver(containerRef, () => {
        const [left, right] = manageGradients(containerRef.current);
        setHasRightGradient(right);
        setHasLeftGradient(left);
    });

    const didMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsDragging(true);
        setStartX(event.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    }, []);

    const didMouseLeave = useCallback(() => {
        setIsDragging(false);
    }, []);

    const didMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    const didMouseMove = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!isDragging) {
                return;
            }
            event.preventDefault();
            const x = event.pageX - containerRef.current.offsetLeft;
            const scroll = x - startX;
            containerRef.current.scrollLeft = scrollLeft - scroll;
        },
        [isDragging, startX, scrollLeft]
    );

    const didContainerScroll = useCallback((event: React.UIEvent) => {
        const [left, right] = manageGradients(event.currentTarget);
        setHasRightGradient(right);
        setHasLeftGradient(left);
    }, []);

    useEffect(() => {
        const [left, right] = manageGradients(containerRef.current);
        setHasRightGradient(right);
        setHasLeftGradient(left);
    }, []);

    return (
        <div className={buildClassNames(styles, ['wrapper'])}>
            <HtmlMarkup tag="h2" markup={section.title} className={styles['title']} />
            <div
                className={buildClassNames(styles, [
                    'directors-content',
                    hasRightGradient && 'directors-content--right-gradient',
                    hasLeftGradient && 'directors-content--left-gradient',
                ])}
            >
                <div
                    className={buildClassNames(styles, ['container'])}
                    ref={containerRef}
                    onMouseDown={didMouseDown}
                    onMouseLeave={didMouseLeave}
                    onMouseUp={didMouseUp}
                    onMouseMove={didMouseMove}
                    onScroll={didContainerScroll}
                >
                    <div className={styles['cards']} ref={directorsRef}>
                        {persons.map((person) => (
                            <DirectorCard key={person.email} person={person} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
