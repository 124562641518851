import { FC } from 'react';
import styles from './PartnerCalculatorTitleCard.module.scss';
import SectionPart from '@app/ui/SectionPart/SectionPart';
import Image from '@app/components/Image/Image';

interface IProps {
    className?: string;
    titleText: string;
}

const PartnerCalculatorTitleCard: FC<IProps> = props => {
    const {
        className,
        titleText,
    } = props;
    return (
        <SectionPart className={className}>
            <h2 className={styles['title']}>{titleText}</h2>
            <div className={styles['bg-wrapper']}>
                <Image className={styles['bg-image']} src="/next/assets/partners-calculator-background.svg" alt="" fill />
            </div>
        </SectionPart>
    );
};

export default PartnerCalculatorTitleCard;
