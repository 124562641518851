import React from 'react';
import Tooltip from '@frontend/jetlend-web-ui/src/ui/TooltipIcon/Tooltip';
import Image from '@app/components/Image/Image';
import { PartnerItemApiModel } from '@app/models/sections/partners';
import styles from './PartnersItem.module.scss';

export default function PartnersItem({
    title,
    image,
}: PartnerItemApiModel) {
    return (
        <Tooltip title={title}>
            <div className={styles['container']}>
                <Image
                    src={image}
                    alt=""
                    fill
                    className={styles['image']}
                />
            </div>
        </Tooltip>
    );
}
