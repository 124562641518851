'use client';

import React from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import styles from './InviteFriendBanner.module.scss';
import ShowMore from '@app/ui/ShowMore/ShowMore';
import { accountInfoHandler } from '@app/ducks/common/account';

const INVEST_LINK = '/invest/v3/bonus/friends';

export interface IProps {
    /** Текст на баннере */
    title: string;
    /** Текст на кнопке */
    buttonText?: string;
}

/** Компонент для отрисовки баннера с CTA кнопкой приглашения */
export default function InviteFriendBanner({
    title,
    buttonText = 'Пригласить друга',
}: IProps) {
    const [account, isFetching] = useSimpleApiHandler(accountInfoHandler);

    const buttonLink = account ? INVEST_LINK : `/invest/login/?return_uri=${INVEST_LINK}`;

    return (
        <div className={styles['block']}>
            <div className={styles['block--title']}>
                {title}
            </div>
            <ShowMore
                linkText={buttonText}
                linkHref={buttonLink}
                type="light"
                size="large"
            />
        </div>
    );
}
