'use client';

import { VictoryPie } from 'victory';
import { useMemo } from 'react';
import {
    EventPropTypeInterface,
    NumberOrCallback,
    StringOrNumberOrCallback,
    VictoryStyleInterface,
} from 'victory-core';
import { VictorySliceTTargetType } from 'victory-pie/lib/slice';
import { formatPercentExt } from '@frontend/jetlend-core/src/formatters/formatUtils';
import PieChartInnerComponent from '@app/components/PieChartBlock/PieChartInnerComponent/PieChartInnerComponent';
import SliceWithTooltip from '@app/components/PieChartBlock/SliceWithTooltip/SliceWithTooltip';
import {
    IChartDataApiModel,
    InvestmentsChartType,
} from '@app/models/features/investments';
import styles from './PieChart.module.scss';
import { IChartSectionApiModel } from '@app/models/sections/chart';

export interface IProps {
    /**
     * Данные секции из WP
     */
    section: IChartSectionApiModel<InvestmentsChartType>;
    /**
     * Данные из API
     */
    chartData: IChartDataApiModel;
}

/**
 * Размер круговой диаграммы
 */
export const CHART_SIZE = 1000;
const INNER_RADIUS_RATIO = 3.4;
/**
 * Цвета круговой диаграммы
 */
export const CHART_COLORS = [
    '#0CD193',
    '#5D78BD',
    '#F4994B',
    '#73CEFD',
    '#ED8DEF',
    '#278FEF',
    '#F23333',
    '#F233D3',
    '#33F25D',
    '#34F2F2',
    '#C0C0C0',
];

/**
 * Круговой график
 */
export default function PieChart({
    section,
    chartData,
}: IProps) {
    const values = chartData?.values;

    const data = useMemo(() => values?.map(({
        part,
        title,
    }, index) => ({
        y: chartData ? (360 * part / 100) : 0,
        color: CHART_COLORS[values.length - 1 === index ? CHART_COLORS.length - 1 : index],
        radius: index % 2 ? CHART_SIZE / 2.2 - CHART_SIZE / 100 : CHART_SIZE / 2 - CHART_SIZE / 100,
        title: `${title}: ${formatPercentExt(part, {
            addSpace: false,
            fractionDigits: 2,
        })}`,
    })), [chartData, values]);

    const events: EventPropTypeInterface<VictorySliceTTargetType, StringOrNumberOrCallback | string[] | number[]>[] = useMemo(() => [{
        target: 'data',
        eventHandlers: {
            onMouseOver: () => ({
                target: 'data',
                mutation: (props) => {
                    return ({
                        ...props,
                        active: true,
                    });
                },
            }),
            onMouseOut: () => ({
                target: 'data',
                mutation: (props) => {
                    return ({
                        ...props,
                        active: false,
                    });
                },
            }),
            onTouchStart: () => ({
                mutation: (props) => ({
                    ...props,
                    active: true,
                }),
            }),
            onTouchEnd: () => ({
                mutation: (props) => ({
                    ...props,
                    active: false,
                }),
            }),
        },
    }], []);

    const style: VictoryStyleInterface = useMemo(() => ({
        data: {
            fill: ({ datum }) => datum.color,
        },
    }), []);

    const radius: NumberOrCallback = useMemo(() =>
        ({ datum }) => datum.radius
    , []);

    return (
        <div className={styles['chart']}>
            <div className={styles['chart__inner']}>
                <PieChartInnerComponent section={section} chartData={chartData} />
            </div>
            <VictoryPie
                width={CHART_SIZE}
                height={CHART_SIZE}
                data={data}
                innerRadius={CHART_SIZE / INNER_RADIUS_RATIO}
                radius={radius}
                padAngle={1.5}
                startAngle={370}
                endAngle={10}
                style={style}
                events={events}
                labels={() => null}
                dataComponent={<SliceWithTooltip />}
            />
        </div>
    );
}
