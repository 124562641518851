'use client';

import {
    FC,
    memo,
} from 'react';

import { IPartnersFeatureType } from '@app/models/features/partners';
import styles from './RevenueFeature.module.scss';
import RevenueCard from './RevenueCard/RevenueCard';
import WithdrawCard from './WithdrawCard/WithdrawCard';
import ProductCard from './ProductCard/ProductCard';
import { IFeatureBlockApiModel } from '@app/models/sections/features';

interface Props {
    sectionBlocks: Record<string, IFeatureBlockApiModel<IPartnersFeatureType>>;
    title?: string;
}

const RevenueFeature: FC<Props> = memo(props => {
    const {
        sectionBlocks,
        title,
    } = props;
    const {
        revenue,
        autowithdraw,
        bestProduct,
    } = sectionBlocks;

    return (
        <div className={styles['feature-wrapper']}>
            <h2 className={styles['title']}>{title}</h2>
            <div className={styles['features']}>
                {revenue && (
                    <RevenueCard
                        className={styles['revenue']}
                        titleText={revenue.title}
                        descriptionText={revenue.description}
                        revenueValue={revenue.value}
                        revenueDescriptionText={revenue.value_description}
                    />
                )}
                {autowithdraw && (
                    <WithdrawCard
                        className={styles['withdraw']}
                        titleText={autowithdraw.title}
                        descriptionText={autowithdraw.description}
                    />
                )}
                {bestProduct && (
                    <ProductCard
                        className={styles['product']}
                        titleText={bestProduct.title}
                        descriptionText={bestProduct.description}
                    />
                )}
            </div>
        </div>
    );
});

export default RevenueFeature;
