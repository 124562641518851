import React from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { InvestmentsBannersFeatureType } from '@app/models/features/investments';
import styles from './InvestmentsBannersSection.module.scss';
import Image from '@app/components/Image/Image';
import HtmlMarkup from '@app/components/HtmlMarkup/HtmlMarkup';
import { FeaturesSectionType } from '@app/models/sections/features';

export default function InvestmentsBannersSection({ section }: FeaturesSectionType) {
    return (
        <div className={styles['section']}>
            <HtmlMarkup
                tag="h2"
                markup={section.title}
                className={styles['section__title']}
            />
            <div className={styles['container']}>
                {section.blocks.map((block) => {

                    const blockClassNames = buildClassNames(styles, [
                        'block',
                        block.id === InvestmentsBannersFeatureType.BANNERS_PRIVILEGE && 'block__privilege',
                    ]);

                    return (
                        <div className={blockClassNames} key={block.id}>
                            <div className={styles['block__content']}>
                                <HtmlMarkup
                                    tag="p"
                                    markup={block.title}
                                    className={styles['block__title']}
                                />
                                {block.description && (
                                    <HtmlMarkup
                                        tag="p"
                                        markup={block.description}
                                        className={styles['block__description']}
                                    />
                                )}
                                {(block.value_description || block.value) && (
                                    <div className={styles['block__value-container']}>
                                        {block.value_description && (
                                            <p className={styles['block__value-description']}>
                                                {block.value_description}
                                            </p>
                                        )}
                                        {block.value && (
                                            <p className={styles['block__value']}>
                                                {block.value}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                            <Image className={styles['block__background-image']} quality={100} src={block.image} alt="" fill />
                        </div>
                    );
                })}
            </div>
            <p className={styles['section__description']}>{section.description}</p>
        </div>
    );
}