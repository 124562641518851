'use client';

import Tooltip from '@frontend/jetlend-web-ui/src/ui/TooltipIcon/Tooltip';
import Link from 'next/link';
import React, { useCallback } from 'react';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    isMobile,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import styles from '@app/sections/StepsSection/StepsSectionBrokersCard/StepsSectionBrokersCard.module.scss';
import Image from '@app/components/Image/Image';
import { sendEvent } from '@app/ducks/common/analytics';

import { IBrokerModel } from '@app/models/common/common';

export interface IProps extends IBrokerModel {
    /**
     * Размер кнопки брокера.
     */
    size?: 'small'|'default';
}

/**
 * Компонент кликабельной иконки брокера.
 */
export default function StepsSectionBrokerImage({
    icon,
    id,
    title,
    url,
    size = 'default',
}: IProps) {
    const image = (
        <div className={styles['icon']}>
            <Image src={icon} fill alt="" quality={100} />
        </div>
    );

    const sendEventAction = useActions(sendEvent);
    const didLinkClicked = useCallback(() => {
        sendEventAction(`investments--broker-${id}-click`);
    }, [ id, sendEventAction ]);

    const className = buildClassNames(styles, [
        'broker',
        `broker--size-${size}`,
    ]);

    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);

    // На мобильных девайсах отключаем тултипы, чтобы клик по брокеру сразу же проходил и не резал конверсию
    return (
        <Tooltip title={title} position="top" disabled={isMobileScreen}>
            {url
                ? (
                    <Link
                        onClick={didLinkClicked}
                        href={url}
                        className={className}
                        target="_blank"
                        rel="nofollow noreferrer"
                    >
                        {image}
                    </Link>
                )
                : (
                    <div className={className}>
                        {image}
                    </div>
                )
            }
        </Tooltip>
    );
}