'use client';

import Button, { ButtonProps } from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import React, { useMemo } from 'react';
import { isMobile } from 'mobile-device-detect';
import useEventCallback from '@frontend/jetlend-core/src/hooks/useEventCallback';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';

import {
    investmentsBrokersModalHandler,
    investmentsPresentationModalHandler,
} from '@app/ducks/investments';

/**
 * Компонент кнопки просмотра презентации инвесторам.
 */
export default function PdfButton(props: ButtonProps) {
    const {
        text = 'Ознакомиться с презентацией',
        href,
    } = props;
    const open = useActions(investmentsPresentationModalHandler.open);
    const openBrokersModal = useActions(investmentsBrokersModalHandler.open);

    const isTablet = useMemo(() => {
        if (typeof navigator === 'undefined') {
            return false;
        }

        const userAgent = navigator.userAgent;
        const isAndroidTablet = /Android|Tablet|Pad|Tab/i.test(userAgent) && !/Mobile/i.test(userAgent);
        const isAppleTablet = (/iPad|Macintosh/i.test(userAgent)) && navigator.maxTouchPoints > 1;
        return isAndroidTablet || isAppleTablet;
    }, []);

    const didButtonClicked = useEventCallback((e: React.MouseEvent) => {
        // Если это ссылка на модальное окно с брокерами, то открываем его
        const isBrokersModalLink = href.endsWith('#modal-brokers');

        if (isBrokersModalLink) {
            e.preventDefault();
            return openBrokersModal();
        }

        // Если это ссылка не на pdf файл, то просто открываем ее в новой вкладке
        const isPresentationLink = href.endsWith('.pdf');

        // Для мобилок и планшетов открываем презентацию в новой вкладке без модального окна, т.к. там не работает механизм с iframe
        const isDesktop = !isMobile && !isTablet;

        // Открываем модальное окно, но оставляем возможность открыть документ в новой вкладке
        if (isPresentationLink && isDesktop) {
            e.preventDefault();
            open({ href });
        }

        return null;
    });

    return (
        <Button
            {...props}
            text={text}
            onClick={didButtonClicked}
        />
    );
}
