import BecomeBorrowerForm from '@app/features/borrowers/BecomeBorrowerForm/BecomeBorrowerForm';
import styles from './ConditionsFeature.module.scss';
import Condition from './Condition/Condition';
import { FeaturesSectionType } from '@app/models/sections/features';

export default function ConditionsFeature({ section }: FeaturesSectionType) {
    return (
        <div className={styles['feature']}>
            <h2 className={styles['feature-title']}>{section.title}</h2>
            <div className={styles['conditions']}>
                {section.blocks.map(block => (
                    <Condition
                        key={block.id}
                        title={block.title}
                        description={block.description}
                        value={block.value}
                        valueDescription={block.value_description}
                        imageSrc={block.image}
                    />
                ))}
            </div>
            <div className={styles['button-wrapper']}>
                <BecomeBorrowerForm buttonText="Получить финансирование" />
            </div>
        </div>
    );
}
