import {
    WORD_FORM_SCOPE,
    wordForm,
} from '@frontend/jetlend-core/src/utils/langUtils';
import {
    useEffect,
    useState,
} from 'react';
import moment from 'moment/moment';
import styles from './CountDownTimer.module.scss';
import HtmlMarkup from '@app/components/HtmlMarkup/HtmlMarkup';

export interface IProps {
    /**
     * Дата до которой таймер будет вести обратный отсчет.
     */
    date: string;
    /**
     * Элементы времени, которые будут отображаться внутри таймера.
     */
    indicators?: WORD_FORM_SCOPE[];
    /**
     * Заголовок таймера
     */
    title?: string;
}

/**
 * Компонент отображающий таймер обратного отсчета до определенной даты.
 */
export default function CountDownTimer({
    date,
    indicators = ['day', 'hours', 'minutes', 'seconds'],
    title,
}: IProps) {
    const defaultTimer = indicators.reduce((acc, key) => {
        acc[key] = '00';
        return acc;
    }, {});

    const [timer, setTimer] = useState(defaultTimer);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            const then = moment.utc(date); // Event final Date
            const now = moment.utc();

            const countdown = moment.utc(then.diff(now));

            const newTimer: Record<string, string> = {};

            indicators.forEach((key) => {
                switch (key) {
                case 'day':
                    // eslint-disable-next-line no-case-declarations
                    let day = then.utc().diff(now, 'days').toString();
                    if (Number(day) < 10) {
                        day = `0${day}`;
                    }
                    newTimer[key] = day;
                    break;
                case 'hours':
                    newTimer[key] = countdown.utc().format('HH');
                    break;
                case 'minutes':
                    newTimer[key] = countdown.utc().format('mm');
                    break;
                case 'seconds':
                    newTimer[key] = countdown.utc().format('ss');
                    break;
                default:
                    newTimer[key] = '00';
                }
            });

            if (now.isAfter(then.toISOString())) {
                clearInterval(timerInterval);
                setTimer(defaultTimer);
            } else {
                setTimer(newTimer);
            }
        }, 1000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [date, defaultTimer, indicators]);

    return (
        <div className={styles['wrapper']}>
            <div className={styles['container']}>
                <div className={styles['timerContainer']}>
                    {indicators.map(item => (
                        <div key={item} className={styles['block']}>
                            <div className={styles['number']}>
                                {timer[item]}
                            </div>
                            <div className={styles['subtitle']}>
                                {wordForm(Number(timer[item]), item)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {title && (
                <HtmlMarkup tag="p" markup={title} className={styles['title']} />
            )}
        </div>
    );
}