import React from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import PieChartBlock from '@app/components/PieChartBlock/PieChartBlock';
import { businessAreasChartHandler } from '@app/ducks/investments';
import { InvestmentsChartType } from '@app/models/features/investments';
import { ChartSectionType } from '@app/models/sections/chart';

/**
 * Круговой график "Сферы бизнеса"
 */
export default function InvestmentsBusinessAreasChart({ section }: ChartSectionType<InvestmentsChartType>) {
    const [chartData] = useSimpleApiHandler(businessAreasChartHandler);

    return <PieChartBlock section={section} chartData={chartData} />;
}