import { TShowMoreSize } from './ShowMore';

/** Возвращает линейный размер иконки в зависимости от размера кнопки */
export const getIconSize = (size: TShowMoreSize): number => {
    switch (size) {
    case 'default':
        return 11;
    case 'large':
        return 16;
    default:
        return 11;
    }
};