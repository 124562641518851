import React, {
    useMemo,
    useRef,
} from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Responsive, {
    isMobile,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { CHART_AXIS_COLOR } from '@frontend/jetlend-web-ui/src/controls/charts/common';
import { useResizeObserver } from '@frontend/jetlend-web-ui/src/hooks/useResizeObserver';
import { IStrategyGroupInfo } from '@app/models/common/autoinvest';
import {
    buildXAxisLabel,
    getAnnotationsArray,
    getNotEmptyData,
} from './StrategyExpectedYieldChart.helper';

import styles from './strategyExpectedYieldChart.module.scss';

interface IProps {
    /**
     * Данные стратегий.
     */
    data: IStrategyGroupInfo;
}

/**
 * Компонент для отрисовки графика доверительного интервала
 */
const StrategyExpectedYieldChart:React.FC<IProps> = (props: IProps) => {
    const { data } = props;
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [chartContainerWidth] = useResizeObserver(chartContainerRef);
    const { width } = useWindowSize();
    const isMobileSize = isMobile(width);

    const series = useMemo(() => ([{
        name: '',
        data: data.expected_yield_values.map(item => ({
            x: item.x,
            y: item.probability,
        })),
    }]), [data]);

    const gradientStops = useMemo(() => {
        const lastDataElement = data.expected_yield_values[data.expected_yield_values.length-1];
        const min = (data.sigma[1].x / lastDataElement.x) * 100;
        const max = (data.sigma[data.sigma.length-2].x / lastDataElement.x) * 100;

        return {
            min,
            max,
        };
    }, [data]);

    const options: ApexOptions = useMemo(() => ({
        chart: {
            type: 'area',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false,
            },
            zoom: {
                enabled: false,
                type: 'x',
                autoScaleYaxis: false,
            },
            background: '#fff',
            parentHeightOffset: 0,
            width: '100%',
        },
        grid: {
            borderColor: CHART_AXIS_COLOR,
            strokeDashArray: 4,
            yaxis: { lines: { show: true } },
            xaxis: { lines: { show: false } },
            padding: {
                right: 30,
                left: 30,
                top: -10,
                bottom: 0,
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
            colors: ['#20836D'],
        },
        dataLabels: {
            enabled: false,
        },
        colors: ['#20836D'],
        tooltip: {
            enabled: false,
        },
        annotations: {
            xaxis: [
                ...getAnnotationsArray(data.sigma, isMobileSize).flat(),
                ...buildXAxisLabel(getNotEmptyData(data.expected_yield_values), chartContainerWidth),
            ],
        },
        xaxis: {
            labels: {
                style: {
                    colors: 'transparent',
                },
            },
            axisTicks: {
                show: false,
            },
            tickPlacement: 'between',
            axisBorder: {
                show: true,
                color: '#64696C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            show: false,
        },
        fill: {
            colors: ['#BDE3DC'],
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 1,
                inverseColors: true,
                opacityFrom: 0,
                opacityTo: 1,
                stops: [gradientStops.min, 0, gradientStops.max, 0],
            },
        },
    }), [isMobileSize, gradientStops, data, styles, chartContainerWidth]);

    return (
        <div ref={chartContainerRef} className={styles['container']}>
            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={isMobileSize ? 300 : 550}
            />
            <Responsive
                mobile={(
                    <div className={styles['tooltip-mobile']}>
                        <div className={styles['tooltip-mobile--info']}>Ожидаемая доходность <br /> с вероятностью 95%</div>
                        95%
                    </div>
                )}
            >
                <div className={styles['tooltip']}>
                    Ожидаемая доходность <br /> с вероятностью 95%
                </div>
            </Responsive>
        </div>
    );
};

export default StrategyExpectedYieldChart;
