import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import Link from 'next/link';
import styles from './ShowMore.module.scss';
import Image from '@app/components/Image/Image';
import UpRightArrow from '@app/icons/up-right-arrow.svg';
import UpRightArrowBlack from '@app/icons/up-right-arrow--black.svg';
import { getIconSize } from './ShowMore.utils';

export type TShowMoreSize = 'default' | 'large';
export type TShowMoreType = 'dark' | 'light';

interface IProps {
    /** Текст на кнопке */
    linkText?: string;
    /** Ссылка */
    linkHref?: string;
    className?: string;
    /** Тип кнопки, светлая/тёмная */
    type?: TShowMoreType;
    /** Размер кнопки */
    size?: TShowMoreSize;
}

/** Компонент кнопки "Подробнее" со стрелой */
export default function ShowMore ({
    linkText = 'Подробнее',
    linkHref = '/investor',
    className,
    type = 'dark',
    size = 'default',
}: IProps) {

    const linkClassName = mergeClassNames([
        buildClassNames(styles, [
            'show-more-link',
            `show-more-link--${type}`,
            `show-more-link--${size}`,
        ]),
        className,
    ]);

    const textClassName = mergeClassNames([
        buildClassNames(styles, [
            'link-text',
            `link-text--${type}`,
            `link-text--${size}`,
        ]),
        className,
    ]);

    const iconSrc = type === 'dark' ? UpRightArrow : UpRightArrowBlack;
    const iconSize = getIconSize(size);

    return (
        <Link className={linkClassName} href={linkHref} rel="nofollow">
            <span className={textClassName}>{linkText}</span>
            <Image src={iconSrc} alt="" width={iconSize} height={iconSize} />
        </Link>
    );
};