import React, {
    PropsWithChildren,
    useCallback,
} from 'react';
import Loader from '@frontend/jetlend-web-ui/src/ui/loaders/Loader';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import Tooltip from '@frontend/jetlend-web-ui/src/ui/TooltipIcon/Tooltip';

import styles from './blockSelectionButton.module.scss';
import Image from '@app/components/Image/Image';

export interface IProps extends PropsWithChildren {
    /**
     * Заголовок кнопки
     */
    title: string;
    /**
     * Выбрана ли текущая стратегия
     */
    selected?: boolean;
    /**
     * Кнопка не активна для действий
     */
    disabled?: boolean;
    /**
     * Отображается иконка с загрузкой загрузка
     */
    loading?: boolean;
    /**
     * Текущая стратегия
     */
    value: string;
    /**
     * Скрытие чекбокса
     */
    hideCheckbox?: boolean;
    /**
     * Дата, после которой использование Агрессивной и Венчурной стратегии будет под запретом без прохождения теста
     */
    date?: string;
    /**
     * Иконка кнопки
     */
    icon?: string;
    /**
     * Срабатывает, когда пользователь меняет стратегию
     * @param value выбранная стратегия
     */
    onChange?: (value: string) => void;
}

/**
 * Функция для получения тултипа иконки
 */
export const getStrategyTooltipText = (date: string) => `Использование данной стратегии после ${date} будет доступно только после прохождения квалификационного теста или подтверждения квалификации по методологии ЦБ РФ.`;

/**
 * Компонент для отрисовки кнопок выбора стратегий (Консервативная, Сбалансированная и тд.).
 */
const BlockSelectionButton: React.FC<IProps> = props => {
    const {
        title,
        selected,
        value,
        onChange,
        icon,
        disabled,
        loading,
        date,
    } = props;

    const didClicked = useCallback(() => {
        if (disabled || selected) {
            return;
        }
        onChange && onChange(value);
    }, [ onChange, value, disabled || selected]);

    const buttonClassNames = buildClassNames(
        styles,
        [
            'button',
            disabled && 'disabled',
            selected && 'active',
        ]
    );

    return (
        <button
            className={buttonClassNames}
            type="button"
            onClick={didClicked}
            disabled={selected || disabled}
        >
            <div className={styles['header']}>
                <div className={styles['title']}>
                    {title}
                    {icon && (
                        <Tooltip containerClassName={styles['tooltip']} title={date && getStrategyTooltipText(date)}>
                            <Image src={icon} alt="" />
                        </Tooltip>
                    )}
                </div>
                {loading &&
                    <Loader className={styles['icon']} size="normal" />
                }
            </div>
            <div className={styles['body']}>
                {props.children}
            </div>
        </button>
    );
};

export default BlockSelectionButton;