'use client';

import React, { useCallback } from 'react';
import Button, { ButtonSize } from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { MaskInputField } from '@frontend/jetlend-web-ui/src/ui/inputs/MaskInput/MaskInput';
import connectToForm from '@frontend/jetlend-core/src/ducks/connectToForm';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import { startRegistration } from '@app/ducks/common/registration';
import {
    ClientType,
    IPhoneFormModel,
} from '@app/models/common/common';
import { sendEvent } from '@app/ducks/common/analytics';
import { getCorrectFirstPhoneNumberFigure } from '@app/utils/getCorrectFirstPhoneNumberFigure';
import { borrowersPhoneFormHandler } from '@app/ducks/borrowers/borrowers';

import styles from './BecomeBorrowerForm.module.scss';

interface IProps {
    /**
     * Растянуть ширину кнопки
     */
    block?: boolean;
    /**
     * Размер кнопки
     */
    size?: ButtonSize;
    /**
     * Ширина кнопки
     */
    width?: number;
    /**
     * Текст кнопки
     */
    buttonText?: string;
    /**
     * Название класса для контейнера кнопки
     */
    containerClassName?: string;
    /**
     * Тип формы (просто кнопка или форма с полем ввода)
     */
    type?: 'button' | 'form';
}

export default connectToForm<IProps, IPhoneFormModel>(function BecomeBorrowerForm({
    block = false,
    size = 'big',
    width,
    buttonText,
    containerClassName,
    type = 'button',
    handleSubmit,
}) {
    const text = buttonText || 'Подать заявку на займ';
    const startRegistrationAction = useActions(startRegistration);
    const sendEventAction = useActions(sendEvent);

    const didButtonClicked = useCallback(() => {
        sendEventAction('borrower-button');
        startRegistrationAction(ClientType.BORROWER);
    }, [sendEventAction, startRegistrationAction]);

    const input = (
        <div className={styles['input']}>
            <MaskInputField
                mask="+7 000 000-00-00"
                prepareChar={getCorrectFirstPhoneNumberFigure}
                label="Номер телефона...."
                name="phone"
                inputType="tel"
                autoComplete="tel"
                className={styles['input-component']}
            />
        </div>
    );

    const button = (
        <Button
            text={text}
            size="big"
            onClick={handleSubmit}
            className={styles['button']}
            width={250}
        />
    );

    if (type === 'form') {
        return (
            <form className={styles['container']} data-testid="become-client-form">
                {input}
                {React.cloneElement(button, {
                    buttonType: 'submit',
                    className: buildClassNames(styles, ['button', 'button--with-input']),
                    textClassName: styles['button--with-input__text'],
                })}
            </form>
        );
    }

    return (
        <Button
            text={text}
            size={size}
            noSpacing
            block={block}
            onClick={didButtonClicked}
            width={width}
            containerClassName={containerClassName}
        />
    );
}, borrowersPhoneFormHandler);