'use client';

import { formatPercent } from '@frontend/jetlend-core/src/formatters/formatUtils';
import PropertyItem, {
    PropertyItemAlign,
    PropertyItemSize,
} from '@frontend/jetlend-web-ui/src/components/PropertyItem/PropertyItem';
import PropertiesGrid from '@frontend/jetlend-web-ui/src/components/PropertiesGrid/PropertiesGrid';
import {
    isMobile,
    isSmallMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import React from 'react';
import styles from './InvestorsGeographySection.module.scss';
import Image from '@app/components/Image/Image';
import { borrowersLocationsHandler } from '@app/ducks/investors/investors';
import { GeographySectionType } from '@app/models/sections/geography';

function getPropertySize(screenWidth: number): PropertyItemSize {
    if (isSmallMobile(screenWidth)) {
        return 'small';
    }

    if (isMobile(screenWidth)) {
        return 'large';
    }

    if (isTablet(screenWidth)) {
        return 'large';
    }

    return 'giant';
}

/**
 * Секция, где отображена география заемщиков
 */
export default function InvestorsGeographySection({
    section: {
        image,
    },
}: GeographySectionType) {
    const [data, isFetching] = useSimpleApiHandler(borrowersLocationsHandler);

    const { width: screenWidth } = useWindowSize();

    const propertySize = getPropertySize(screenWidth);

    const alignment: PropertyItemAlign = isMobile(screenWidth) ? 'center' : 'left';

    return (
        <div>
            <PropertiesGrid justify="between">
                {!isFetching
                    ? (
                        data?.values?.map(region => (
                            <PropertyItem
                                key={region.title}
                                title={region.title}
                                value={formatPercent(region.part)}
                                align={alignment}
                                inverted
                                size={propertySize}
                                noPadding
                                className={styles['property-item']}
                            />
                        ))
                    )
                    : <LoaderBlock />
                }
            </PropertiesGrid>
            <div className={styles['borrowers-map']}>
                <Image
                    src={image}
                    alt=""
                    fill
                />
            </div>
        </div>
    );
};
