'use client';

import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import React, { useCallback } from 'react';
import { MaskInputField } from '@frontend/jetlend-web-ui/src/ui/inputs/MaskInput/MaskInput';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import { redirectToExternal } from '@frontend/jetlend-core/src/ducks/router';
import connectToForm from '@frontend/jetlend-core/src/ducks/connectToForm';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import styles from './BecomeInvestorForm.module.scss';
import SectionPart from '@app/ui/SectionPart/SectionPart';
import { investorsPhoneFormHandler } from '@app/ducks/investors/investors';
import { startRegistration } from '@app/ducks/common/registration';
import { ClientType } from '@app/models/common/common';
import { sendEvent } from '@app/ducks/common/analytics';
import { getCorrectFirstPhoneNumberFigure } from '@app/utils/getCorrectFirstPhoneNumberFigure';
import { accountInfoHandler } from '@app/ducks/common/account';

interface IProps {
    /**
     * Название типа секции, которое будет использовать для аналитики.
     */
    sectionType?: string;
    /**
     * Тип отображения блока.
     */
    type?: 'section-part' | 'short' | 'button' | 'button-block';
    /**
     * Разрешить использование тени.
     */
    withShadow?: boolean;
    /**
     * Текст кнопки начала регистрации.
     */
    buttonText?: string;
    /**
     * Отправлять на страницу пополнения, если пользователь авторизован.
     */
    addFundsWhenAuth?: boolean;
}

/**
 * Компонент отображения быстрой формы регистрации инвестора.
 */
export default connectToForm<IProps, any>(function BecomeInvestorForm({
    sectionType,
    type = 'section-part',
    withShadow,
    handleSubmit,
    buttonText = 'Начать инвестировать',
    addFundsWhenAuth,
    invalid,
}) {
    const text = buttonText || 'Начать инвестировать';

    const [account] = useSimpleApiHandler(accountInfoHandler);

    const startRegistrationAction = useActions(startRegistration);
    const sendEventAction = useActions(sendEvent);
    const redirectToExternalAction = useActions(redirectToExternal);

    const didBecomeInvestorManualButtonClicked = useCallback(() => {
        if (addFundsWhenAuth && account && account.user_id) {
            redirectToExternalAction(`${document.location.origin}/invest/v3#modal-addfunds`);
            return;
        }

        startRegistrationAction(ClientType.INVESTOR);

        sendEventAction('investor-button');
        sendEventAction('investor-button--simple');

        if (sectionType) {
            sendEventAction(`investor--registration--${sectionType}`);
        }
    }, [sectionType, account, addFundsWhenAuth, redirectToExternalAction, sendEventAction, startRegistrationAction]);

    const didSubmitted = useCallback((e) => {
        if (invalid) {
            sendEventAction('investor--invalid-phone-registration');
        } else if (sectionType) {
            sendEventAction(`investor--registration--${sectionType}`);
        }

        handleSubmit(e);
    }, [ sectionType, handleSubmit, invalid, sendEventAction]);

    const input = (
        <div className={styles['input']}>
            <MaskInputField
                mask="+7 000 000-00-00"
                prepareChar={getCorrectFirstPhoneNumberFigure}
                label="Номер телефона...."
                name="phone"
                inputType="tel"
                autoComplete="tel"
                className={styles['input-component']}
            />
        </div>
    );

    const button = (
        <Button text={buttonText} size="big" onClick={didSubmitted} className={styles['button']} width={250} />
    );

    if (type === 'section-part') {
        return (
            <SectionPart withShadow={withShadow} className={styles['section']}>
                <form className={styles['container']} onSubmit={didSubmitted} data-testid="become-client-form">
                    <p className={styles['call-to-action']}>Начните инвестировать сегодня</p>
                    <hr className={styles['delimiter']} />
                    {input}
                    {React.cloneElement(button, {
                        buttonType: 'submit',
                        className: buildClassNames(styles, ['button', 'button--with-input']),
                        textClassName: styles['button--with-input__text'],
                    })}
                </form>
            </SectionPart>
        );
    }

    if (type === 'short') {
        return (
            <form className={styles['container']} onSubmit={didSubmitted} data-testid="become-client-form">
                {input}
                {React.cloneElement(button, {
                    buttonType: 'submit',
                    className: buildClassNames(styles, ['button', 'button--with-input']),
                    textClassName: styles['button--with-input__text'],
                })}
            </form>
        );
    }

    if (type === 'button' || type === 'button-block') {
        return (
            <Button
                text={text}
                size="big"
                onClick={didBecomeInvestorManualButtonClicked}
                width={type === 'button' ? 250 : undefined}
                block={type === 'button-block'}
                noSpacing
                containerClassName={styles['buttonContainer']}
            />
        );
    }
}, investorsPhoneFormHandler);

