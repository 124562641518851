'use client';

import React, {
    useCallback,
    useRef,
    useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    isMobile,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import Image from '@app/components/Image/Image';
import styles from './HowItWorksSection.module.scss';

type VideoConfiguration = {
    width: number;
    height: number;
    preview: string;
    video: string;
    video_mp4?: string;
}

const DESKTOP_VIDEO_CONFIG: VideoConfiguration = {
    width: 1280.0,
    height: 720.0,
    preview: '/next/assets/how-it-works/desktop-preview.jpg',
    video: '/next/assets/how-it-works/desktop-video.webm',
};

const MOBILE_VIDEO_CONFIG: VideoConfiguration = {
    width: 720.0,
    height: 1280.0,
    preview: '/next/assets/how-it-works/mobile-preview.jpg',
    video: '/next/assets/how-it-works/mobile-video.webm',
    video_mp4: '/next/assets/how-it-works/mobile-video.mp4',
};

function buildSrc(propsSrc: string): string {
    const basePath = process.env.NEXT_PUBLIC_BASE_PATH;

    return ((typeof propsSrc === 'string') && (propsSrc.startsWith('/')))
        ? `${basePath || ''}${propsSrc}`
        : propsSrc;
}

function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
    ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

export default function HowItWorksSection() {
    const ref = useRef<HTMLDivElement>(null);

    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);

    const configuration = isMobileScreen
        ? MOBILE_VIDEO_CONFIG
        : DESKTOP_VIDEO_CONFIG;

    const [ isPlayable, setPlayable ] = useState(false);
    const didLoaded = useCallback(() => setPlayable(true), []);

    const {
        inView,
        ref: videoContainerRef,
    } = useInView({
        triggerOnce: true,
        threshold: 0.01,
    });

    return (
        <div className={styles['wrapper']}>
            <div ref={ref} className={styles['container']}>
                <div
                    className={buildClassNames(styles, [
                        'preview-image',
                        isPlayable && 'preview-image--loaded',
                    ])}
                >
                    <Image
                        src={MOBILE_VIDEO_CONFIG.preview}
                        alt=""
                        fill
                        className="r-mobile"
                    />
                    <Image
                        src={DESKTOP_VIDEO_CONFIG.preview}
                        alt=""
                        fill
                        className="r-not-mobile"
                    />
                </div>
                <div
                    ref={videoContainerRef}
                    className={buildClassNames(styles, [
                        'video',
                        isPlayable && 'video--loaded',
                    ])}
                >
                    <video
                        src={inView ? buildSrc(iOS() ? configuration.video_mp4 : configuration.video) : undefined}
                        muted
                        autoPlay
                        width="100%"
                        controls={false}
                        playsInline
                        preload="auto"
                        loop
                        onCanPlay={didLoaded}
                    />
                </div>
            </div>
        </div>
    );
}
