import {
    BaseSectionWithBlocksAndDescription,
    HistoryFeatureType,
} from '../common/common';
import { IFeatureBlockApiModel } from '@app/models/sections/features';

export enum IMainPageFeatureType {
    PROFITABILITY = 'profitability',
    SCORING_TIME = 'scoring-time',
    LIMIT = 'limit',
    INTEREST_RATE = 'interest_rate',
    REVOLTING_LINE = 'revolting-line',
    CONVENIENT_PRODUCT = 'convenient_product',
    TRUSTED_PRODUCT = 'trusted_product',
    TRUSTED_PRODUCT_2 = 'trusted_product_2',
    PRODUCT_LINKS = 'product_links',
}

export type MainPageFeaturesSectionApiModel = BaseSectionWithBlocksAndDescription<IFeatureBlockApiModel<IMainPageFeatureType | HistoryFeatureType>>
