import {
    formatAmountShortText,
    formatPercentExt,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import PropertyItem from '@frontend/jetlend-web-ui/src/components/PropertyItem/PropertyItem';
import Tooltip from '@frontend/jetlend-web-ui/src/ui/TooltipIcon/Tooltip';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import React, { useMemo } from 'react';
import success from '@frontend/jetlend-assets/icons/icon--success-alert.svg';
import useObjectHandler from '@frontend/jetlend-core/src/hooks/useObjectHandler';
import { numWordForm } from '@frontend/jetlend-core/src/utils/langUtils';
import useStoreHandler from '@frontend/jetlend-core/src/hooks/useStoreHandler';
import { currentStrategyHandler } from '@app/ducks/autoinvest';
import { investmentCalculatorHandler } from '@app/ducks/investors/investors';
import { IAutoInvestStrategyState } from '@app/models/common/autoinvest';

import styles from './investmentCalculatorResult.module.scss';

const expectedYieldTooltip = 'До вычета НДФЛ, с учетом потерь, с учетом реинвестирования получаемой доходности';

/**
 * Функция, для получения процента ожидаемой доходности с округлением до сотых
 * @param interest_rate ожидаемая доходность
 */
const getInterestRatePercent = (interest_rate: number) => formatPercentExt(interest_rate, { fractionDigits: 2 })?.slice(0, -2);

/**
 * Компонент для отрисовки результата вычислений калькулятора
 */
const InvestmentCalculatorResult = () => {
    const [{ result }] = useObjectHandler(investmentCalculatorHandler);
    const [{ strategiesItemData }] = useStoreHandler<IAutoInvestStrategyState>(currentStrategyHandler);

    const minInterestRate = useMemo(() => getInterestRatePercent(strategiesItemData?.expected_min_interest_rate), [strategiesItemData]);
    const maxInterestRate = useMemo(() => getInterestRatePercent(strategiesItemData?.expected_max_interest_rate), [strategiesItemData]);

    return (
        <div className={styles['range-block']}>
            <div className={styles['range-container']}>
                <div className={styles['property-container']}>
                    <PropertyItem
                        className={styles['property-item']}
                        noPadding
                        title={(
                            <div className={styles['form-label']}>
                                Ожидаемый размер капитала*
                            </div>
                        )}
                        value={(
                            <div className={mergeClassNames([styles['properties-label'], styles['properties-label--grey']])}>
                                <span className={styles['properties-label-item']}>{formatAmountShortText(result?.minExpectedAmount, '')} </span>
                                <span className={styles['properties-label-prefix']}>₽</span>
                                <span className={styles['properties-label-prefix']}> &ndash; </span>
                                <span className={styles['properties-label-item']}>{formatAmountShortText(result?.maxExpectedAmount, '')} </span>
                                <span className={styles['properties-label-prefix']}>₽</span>
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className={styles['range-container']}>
                <div className={styles['property-container']}>
                    <PropertyItem
                        className={styles['property-item']}
                        noPadding
                        title={(
                            <div className={styles['form-label']}>
                                Ожидаемая доходность
                                <Tooltip disableWrapper title={expectedYieldTooltip}>
                                    <img src={getAssetSrc(success)} alt="" />
                                </Tooltip>
                            </div>
                        )}
                        value={(
                            <div className={styles['properties-label']}>
                                <span className={styles['properties-label-item']}>{minInterestRate} </span>
                                <span className={styles['properties-label-prefix']}>%</span>
                                <span className={styles['properties-label-prefix']}> &ndash; </span>
                                <span className={styles['properties-label-item']}>{maxInterestRate} </span>
                                <span className={styles['properties-label-prefix']}>%</span>
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className={styles['range-container']}>
                <div className={styles['property-container']}>
                    <PropertyItem
                        className={styles['property-item']}
                        noPadding
                        title={(
                            <div className={styles['form-label']}>
                                Доход через {numWordForm(result?.termInYears, 'year')}
                            </div>
                        )}
                        value={(
                            <div className={mergeClassNames([styles['properties-label'], styles['properties-label--grey']])}>
                                <span className={styles['properties-label-item']}>{formatAmountShortText(result?.minIncome, '')} </span>
                                <span className={styles['properties-label-prefix']}>₽</span>
                                <span className={styles['properties-label-prefix']}> &ndash; </span>
                                <span className={styles['properties-label-item']}>{formatAmountShortText(result?.maxIncome, '')} </span>
                                <span className={styles['properties-label-prefix']}>₽</span>
                            </div>
                        )}
                    />
                </div>
            </div>
        </div>
    );

};

export default InvestmentCalculatorResult;
