'use client';

import dynamic from 'next/dynamic';
import {
    Children,
    Fragment,
    PropsWithChildren,
} from 'react';
import { Settings } from 'react-slick';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import OffscreenLoader from '@app/ui/OffscreenLoader/OffscreenLoader';
import styles from './CompaniesSlider.module.scss';

const LazySlide = dynamic(() => import('react-slick').then(module => module), { ssr: false });

const NextArrow = (
    <div className={mergeClassNames([styles['arrows'], styles['arrow-right']])}>
        <svg viewBox="0 0 100 100">
            <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#333" transform="translate(100, 100) rotate(180) " />
        </svg>
    </div>
);

const PrevArrow = (
    <div className={mergeClassNames([styles['arrows'], styles['arrow-left']])}>
        <svg viewBox="0 0 100 100">
            <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#333" />
        </svg>
    </div>
);

const DEFAULT_SETTINGS: Settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 1200,
    cssEase: 'linear',
    variableWidth: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    // centerMode: true,
    nextArrow: NextArrow,
    prevArrow: PrevArrow,
    draggable: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 600,
            },
        },
    ],
};

type Props = PropsWithChildren

/**
 * Компонент слайдера для списка компаний
 */
export default function CompaniesSlider({
    children,
}: Props) {
    return (
        <div className={styles['companies-slider']}>
            <OffscreenLoader>
                {({
                    ref,
                    inView,
                }) => (
                    <div ref={ref}>
                        {inView && (
                            <LazySlide {...DEFAULT_SETTINGS}>
                                {Children.map(children, (child, i) => (
                                    <Fragment key={i}>
                                        <div className={styles['item-wrapper']}>{child}</div>
                                    </Fragment>
                                ))}
                            </LazySlide>
                        )}
                    </div>
                )}
            </OffscreenLoader>
        </div>
    );
}