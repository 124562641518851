import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { formatAmountShortText } from '@frontend/jetlend-core/src/formatters/formatUtils';
import styles from './WithdrawExample.module.scss';
import WithdrawIcon from '@app/icons/feature-withdraw.svg';
import Image from '@app/components/Image/Image';

interface IProps {
    amount: number;
    className?: string;
}

const WithdrawExample: React.FC<IProps> = props => {
    const {
        amount,
        className,
    } = props;
    const formatedAmount = `+ ${formatAmountShortText(amount.toFixed(0))}`;

    return (
        <div className={mergeClassNames([styles['withdraw-example'], className])}>
            <div className={styles['logo']}>
                <Image src={WithdrawIcon} alt="withdraw" width={16} height={16} />
            </div>
            <div className={styles['text-info']}>
                <div className={styles['title']}>Вывод вознаграждения</div>
                <div className={styles['description']}>Вознаграждение</div>
            </div>
            <div className={styles['amount']}>{formatedAmount}</div>
        </div>
    );
};

export default WithdrawExample;
