'use client';

import React, { ReactNode } from 'react';
import { Settings } from 'react-slick';
import {
    isDesktop,
    isMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import dynamic from 'next/dynamic';
import CommonCard from '@app/features/investors/InvestorsCommonSlideSection/CommonCard/CommonCard';
import OffscreenLoader from '@app/ui/OffscreenLoader/OffscreenLoader';
import styles from './InvestorsCommonSlideSection.module.scss';
import './slider.scss';
import { getSettings } from '@app/features/investors/InvestorsCommonSlideSection/InvestorsCommonSlideSection.logic';

const LazySlide = dynamic(() => import('react-slick').then(module => module), { ssr: false });

export interface IProps {
    card?: boolean;
    amount?: number;
    noBorder?: boolean;
    showNextSlide?: boolean;
    children?: ReactNode[];
}

export default function InvestorsCommonSlideSection({
    card = true,
    amount = 4,
    children,
    noBorder,
    showNextSlide,
}: IProps) {
    const { width: screenWidth } = useWindowSize();

    const NextArrow = (
        <div className={mergeClassNames([styles['arrows'], styles['arrow-right']])}>
            <svg viewBox="0 0 100 100">
                <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#333" transform="translate(100, 100) rotate(180) " />
            </svg>
        </div>
    );

    const PrevArrow = (
        <div className={mergeClassNames([styles['arrows'], styles['arrow-left']])}>
            <svg viewBox="0 0 100 100">
                <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#333" />
            </svg>
        </div>
    );

    const SLIDER_SETTINGS: Settings = {
        children: [],
        arrows: isDesktop(screenWidth),
        dots: !showNextSlide && (isMobile(screenWidth) || isTablet(screenWidth)),
        autoplay: false,
        infinite: true,
        className: styles['slider'],
        speed: 1200,
        responsive: getSettings(amount),
        nextArrow: NextArrow,
        prevArrow: PrevArrow,
    };

    return (
        <OffscreenLoader>
            {({
                ref,
                inView,
            }) => (
                <div ref={ref} className={styles['container']}>
                    <div className={showNextSlide ? styles['slim'] : undefined}>
                        {inView && (
                            <LazySlide {...SLIDER_SETTINGS}>
                                {children.map((item, i) => (
                                    <React.Fragment key={i}>
                                        {card
                                            ? (
                                                <CommonCard
                                                    className={styles['item-card']}
                                                    noBorder={noBorder}
                                                >
                                                    {item}
                                                </CommonCard>
                                            )
                                            : (
                                                <div className={styles['item-wrapper']}>
                                                    {item}
                                                </div>
                                            )
                                        }
                                    </React.Fragment>
                                ))}
                            </LazySlide>
                        )}
                    </div>
                </div>
            )}
        </OffscreenLoader>
    );
}
