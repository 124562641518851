export interface IPartnerCalculatorFormValues {
    borrowersCount: number;
    averageBorrowAmount: number;
}

export interface IPartnerCalculatorResult {
    expectedIncome: number;
}

export enum IPartnersFeatureType {
    REVENUE = 'revenue',
    AUTOWITHDRAW = 'autowithdraw',
    BEST_PRODUCT = 'best-product',
    ONLINE_DOCUMENTS = 'online-documents',
    ONE_DAY_REGISTRATION = 'one-day-registration',
    PERSONAL_MANAGER = 'personal-manager',
    ALL_IN_ONE_CABINET = 'all-in-one-cabinet',
    REFERRAL_LINKS = 'referral-links',
    API_INTEGRATIONS = 'api-integrations',
}

export interface IPartnersRevenuePointApiModel {
    revenue_percentage: number;
}

export interface IPartnersRevenueStatisticsApiModel {
    max_percentage: number;
}
