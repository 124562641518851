'use client';

import {
    formatAmountShortText,
    formatPercentExt,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import { numWordForm } from '@frontend/jetlend-core/src/utils/langUtils';
import { RATINGS_MAP } from '@frontend/jetlend-core/src/models/rating';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import Image from '@app/components/Image/Image';
import { IInvestedCompany } from '@app/models/sections/companies';
import styles from './CompanyCard.module.scss';

export interface IProps {
    /**
     * Информация о компании
     */
    company: IInvestedCompany;
}

type TableCell = {
    title: string;
    value: string;
}

const address = process.env.NEXT_PUBLIC_JETLEND_MEDIA_ENDPOINT;

/**
 * Компонент карточки компании-заёмщика в слайдере
 */
export default function CompanyCard({
    company,
}: IProps) {

    const tableData: TableCell[] = [
        {
            title: 'Ставка',
            value: formatPercentExt(company.interest_rate, { fractionDigits: 2 }),
        },
        {
            title: 'Срок',
            value: numWordForm(company.term, 'day'),
        },
        {
            title: 'Рейтинг',
            value: RATINGS_MAP[company.rating],
        },
    ];

    return (
        <div className={styles['company-card']}>
            <div className={styles['image']}>
                <Image src={`${address}/${company.preview_url}`} alt="" fill />
            </div>
            <div className={styles['info']}>
                <div className={styles['name']}>
                    {company.company}
                </div>
                <div className={styles['description']}>
                    {company.description}
                </div>
                <div className={styles['amount']}>
                    {formatAmountShortText(company.amount)}
                </div>
                <div className={styles['info-table']}>
                    {tableData.map(item => (
                        <div className={styles['table-item']} key={item.title}>
                            <div className={styles['table-item-title']}>
                                {item.title}
                            </div>
                            <div className={styles['table-item-value']}>
                                {item.value}
                            </div>
                        </div>
                    ))}
                </div>

                <Button
                    type="secondary"
                    text="Подробнее"
                    external
                    href={company?.kontur_url}
                    className={styles['button']}
                />
            </div>
        </div>
    );
}