'use client';

import React, {
    useCallback,
    useState,
} from 'react';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import {
    isMobile,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import Modal from '@frontend/jetlend-web-ui/src/ui/modals/Modal';
import InvestorsCommonSlideSection from '@app/features/investors/InvestorsCommonSlideSection/InvestorsCommonSlideSection';
import FeedbackItem from '@app/sections/FeedbackSection/FeedbackItem';
import { FeedbacksSectionType } from '@app/models/sections/feedback';

export default function FeedbackSection({ section }: FeedbacksSectionType) {
    const { width } = useWindowSize();
    const [ showModal, setShowModal ] = useState(false);
    const [ shownFeedbackIndex, setShownFeedbackIndex ] = useState(0);
    const didModalClosed = useCallback(() => setShowModal(false), []);

    const didMoreClicked = (index: number) => {
        setShownFeedbackIndex(index);
        setShowModal(true);
    };

    return (
        <>
            <Modal
                title=""
                isOpen={showModal}
                closable
                onClosed={didModalClosed}
                contentClassName="d-flex--1"
                footer={isMobile(width) && (
                    <Button type="secondary" text="Закрыть" onClick={didModalClosed} />
                )}
            >
                <FeedbackItem
                    {...section.items?.[shownFeedbackIndex]}
                    showFull
                />
            </Modal>

            <InvestorsCommonSlideSection>
                {section.items?.map((item, itemIndex) => (
                    <FeedbackItem
                        key={`${item.text}-${item.username}-${item.date}`}
                        service={item.service}
                        index={itemIndex}
                        onMoreClick={didMoreClicked}
                        {...item}
                    />
                ))}
            </InvestorsCommonSlideSection>
        </>
    );
}
